import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import "../assets/styles/homepage.scss";
import { OptionCard } from "../components";
import { HomePageOptions } from "../constants";
import { HomePageOption } from "../models";
import { useNavigate } from "react-router";
import HomeFrame from "../../../assets/images/homepage_triangles.svg";
import { useMemberInfo } from "core/MemberInfoProvider/MemberInfoProvider";
import { useGlobalLoader } from "components/common";
import { useEffect } from "react";
import { usePermissions } from "core/navigation/usePermissions";
import "./HomePage.scss";

function HomePage() {
  const { t } = useTranslation(["home", "common"]);
  const navigate = useNavigate();
  const handleOptionActionClick = (redirectURL: string) => {
    navigate(redirectURL);
  };
  const { memberInfo, memberInfoDataIsPending } = useMemberInfo();
  const { showGlobalLoader } = useGlobalLoader();
  const { hasFunctionalAccess } = usePermissions();

  useEffect(() => {
    memberInfoDataIsPending ? showGlobalLoader(true) : showGlobalLoader(false);
  }, [memberInfoDataIsPending, showGlobalLoader]);

  return (
    <>
      {hasFunctionalAccess && memberInfo ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid container spacing={2} mb={2}>
              <Grid size={{ md: 5, xs: 12 }}>
                <Typography className="header-H1">{t("home:title")}</Typography>
                <div className="home-page-description">
                  <Typography sx={{ marginTop: "12px" }}>
                    {t("description.part1")}
                    <span className="subtext">2</span>
                    {t("description.part2")}
                  </Typography>
                </div>
              </Grid>
              <Grid
                size={{ md: 7, xs: 0 }}
                sx={{
                  display: { xs: "none", md: "block" },
                  overflowX: "hidden",
                  overflowY: "hidden",
                }}
              >
                <img
                  src={HomeFrame}
                  height={"100%"}
                  alt="PwC triangle shapes"
                ></img>
              </Grid>
            </Grid>
          </Box>
          <Grid container my={2}>
            {HomePageOptions.map((option: HomePageOption, index: number) => (
              <OptionCard
                key={index}
                icon={option.icon}
                title={t(option.title)}
                actionButtonLabel={t("common:actions.open")}
                actionButtonAriaLabel={t(option.actionButtonLabel)}
                onActionButtonClick={() => {
                  handleOptionActionClick(option.redirectURL);
                }}
                description={t(option.description)}
                permission={option.permission}
              ></OptionCard>
            ))}
          </Grid>
        </>
      ) : null}
    </>
  );
}

export default HomePage;
