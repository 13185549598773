import { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { MacroAdjustmentStepOne } from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepOne/MacroAdjustmentStepOne";
import { MacroAdjustmentStepTwo } from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/MacroAdjustmentStepTwo";
import { MacroAdjustmentStepThree } from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepThree/MacroAdjustmentStepThree";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MacroToolRoutesConfig } from "../../navigation/config";
import { Form, Formik, FormikProps } from "formik";
import * as yup from "yup";
import ConfirmationModal from "../../../../components/common/ConfirmationModal/ConfirmationModal";
import { useModal } from "components/common/Modal";
import _ from "lodash";
import MacroAdjustmentsWizardHeaderAndStepper from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentsWizardHeaderAndStepper/MacroAdjustmentsWizardHeaderAndStepper";
import MacroAdjustmentsWizardFooter from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentsWizardFooter/MacroAdjustmentsWizardFooter";
import {
  MacroAdjustmentCombination,
  MacroAdjustmentCombinations,
  MacroAdjustmentInputAreas,
  MacroAdjustmentPillars,
  MacroAdjustmentStep,
  MacroAdjustmentTypes,
  MacroAdjustmentWizardFormikValues,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";
import {
  useEditMacroScenarioAdjustmentScenariosMacroScenarioIdAdjustmentsAdjustmentIdPatch,
  useGetMacroAdjustmentByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdGet,
  useGetMacroFiltersScenarioMacroFiltersGet,
  getGetAllMacroScenarioAdjustmentsScenariosMacroScenarioIdAdjustmentsGetQueryKey,
  useGetMacroScenarioByIdScenariosMacroScenarioIdGet,
  useMacroIngredientWeightAdjustmentScenariosMacroScenarioIdAdjustmentsIngredientsWeightPost,
  getGetMacroAdjustmentByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdGetQueryKey,
} from "orval/generated/endpoint";
import { useGlobalLoader } from "components/common";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import {
  CountryViewModel,
  CreateIngredientWeightMacroAdjustment,
  type GetMacroFiltersScenarioMacroFiltersGetParams,
  MacroFilter,
  MacroFiltersAndFilteredRTDL,
} from "orval/generated/models";
import { useQueryClient } from "@tanstack/react-query";

const MacroAdjustmentWizard = () => {
  const { t } = useTranslation(["macro", "common"]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal, closeModal } = useModal();
  const { id: urlScenarioId, adjustmentId } = useParams<{
    id: string;
    adjustmentId: string;
  }>();
  const scenarioId = urlScenarioId as string;

  const [activeStep, setActiveStep] = useState(0);
  const { showGlobalLoader } = useGlobalLoader();
  const headersIndent: string = location.state?.headersIndent || "0px";
  const showSnackbar = useSnackbar();
  const [adjustmentCombination, setAdjustmentCombination] =
    useState<MacroAdjustmentCombination | null>(null);

  const [validationErrors, setValidationErrors] = useState<
    Record<number, string | undefined>
  >({});

  const [isFormInitialized, setIsFormInitialized] = useState(false);

  const { data: scenarioDetails } =
    useGetMacroScenarioByIdScenariosMacroScenarioIdGet(parseInt(scenarioId), {
      query: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    });

  const { data: adjustmentDetails } =
    useGetMacroAdjustmentByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdGet(
      parseInt(scenarioId),
      parseInt(adjustmentId as string),
      {
        query: {
          enabled: Boolean(adjustmentId),
          refetchOnMount: false,
          refetchOnWindowFocus: false,
        },
      },
    );

  const [macroFiltersParams, setMacroFiltersParams] =
    useState<GetMacroFiltersScenarioMacroFiltersGetParams>({
      countries: [],
      target_year: 2030,
      categories: [],
      brands: [],
      brand_groups: [],
    });

  useEffect(() => {
    setMacroFiltersParams(
      Boolean(adjustmentId)
        ? {
            countries: adjustmentDetails?.filters.countries as string[],
            target_year: scenarioDetails?.inputs?.years?.target_year || 2030,
            categories: adjustmentDetails?.filters.categories as number[],
            brands: adjustmentDetails?.filters.brands as number[],
            brand_groups: adjustmentDetails?.filters.brand_groups as number[],
          }
        : {
            countries:
              scenarioDetails?.inputs?.countries?.map(
                (country) => country.country,
              ) || [],
            target_year: scenarioDetails?.inputs?.years?.target_year || 2030,
          },
    );
  }, [adjustmentDetails, adjustmentId, scenarioDetails]);

  const {
    data: filtersData,
    isPending: filtersDataIsPending,
    error: errorFetchingFiltersData,
  } = useGetMacroFiltersScenarioMacroFiltersGet(macroFiltersParams, {
    query: {
      enabled:
        Boolean(macroFiltersParams.countries?.length) &&
        Boolean(macroFiltersParams?.target_year),
    },
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    errorFetchingFiltersData
      ? showSnackbar("Error fetching filters data", "error")
      : null;
  }, [errorFetchingFiltersData, showSnackbar]);

  const getStepTitle = (
    stepIndex: number,
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => {
    if (stepIndex === 0) {
      return t("macro:adjustmentsPage.wizard.stepOneTitle");
    } else if (stepIndex === 1 || stepIndex === 2) {
      return (
        formikProps.values.payload.name ||
        t("macro:adjustmentsPage.wizard.stepOneTitle")
      );
    }
  };

  const getStepSubtitle = () => {
    return (
      (adjustmentCombination && adjustmentCombination.name) ||
      t("macro:adjustmentsPage.wizard.stepOneTitle")
    );
  };

  const handleRightButtonClick = async (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => {
    if (activeStep < steps(formikProps).length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      await handleSaveAdjustment(formikProps.values);
    }
  };

  const handleLeftButtonClick = () => {
    if (activeStep === 0) {
      navigateToAdjustments();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const macroAdjustmentValidationSchema = yup.object({
    name: yup
      .string()
      .required(
        t("macro:adjustmentsPage.wizard.validationErrors.adjustmentName"),
      ),
    description: yup.string(),
    inputArea: yup
      .string()
      .required(t("macro:adjustmentsPage.wizard.validationErrors.inputArea")),
  });

  const steps = (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ): MacroAdjustmentStep[] => [
    {
      index: 0,
      label: adjustmentId
        ? null
        : t("macro:adjustmentsPage.wizard.stepOne.label"),
      component: (
        <MacroAdjustmentStepOne
          updateAdjustmentCombination={updateAdjustmentCombination}
          isEditWizard={Boolean(adjustmentId)}
        />
      ),
      leftBtnText: t("common:actions.cancel"),
      rightBtnText: t("common:actions.next"),
      stepTitle: adjustmentId
        ? null
        : t("macro:adjustmentsPage.wizard.stepOneTitle"),
    },
    {
      index: 1,
      label: t("macro:adjustmentsPage.wizard.stepTwo.label"),
      component: (
        <MacroAdjustmentStepTwo
          adjustmentCombination={adjustmentCombination}
          scenario={scenarioDetails}
          filtersData={filtersData as MacroFiltersAndFilteredRTDL}
          setMacroFiltersParams={setMacroFiltersParams}
          filtersDataIsPending={filtersDataIsPending}
        />
      ),
      leftBtnText: t("common:actions.back"),
      rightBtnText: t("common:actions.next"),
      stepTitle: getStepTitle(1, formikProps) || "",
      stepSubtitle: getStepSubtitle(),
    },
    {
      index: 2,
      label: t("macro:adjustmentsPage.wizard.stepThree.label"),
      component: (
        <MacroAdjustmentStepThree
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
          scenario={scenarioDetails}
          adjustmentCombination={adjustmentCombination?.combo}
        />
      ),
      leftBtnText: t("common:actions.back"),
      rightBtnText: t("common:actions.save"),
      stepTitle: getStepTitle(2, formikProps) || "",
      stepSubtitle: getStepSubtitle(),
    },
  ];

  const navigateToAdjustments = () => {
    navigate(MacroToolRoutesConfig.adjustmentsPage.replace(":id", scenarioId), {
      state: {
        headersIndent: headersIndent,
      },
    });
  };

  const handleNavigateToAdjustmentsPage = (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => {
    const showModal = !_.isEqual(formikProps.values, formikProps.initialValues);
    const modalProps = {
      title: t("macro:adjustmentsPage.wizard.lockInModal.title"),
      description: t("macro:adjustmentsPage.wizard.lockInModal.description"),
      actionTitle: t(
        "macro:adjustmentsPage.wizard.lockInModal.backToAdjustments",
      ),
      confirmAction: () => {
        navigateToAdjustments();
        closeModal();
      },
      cancelAction: closeModal,
    };
    showModal
      ? openModal(<ConfirmationModal {...modalProps} />)
      : navigateToAdjustments();
  };

  const {
    mutateAsync: createIngredientWeightsAdjustment,
    isPending: createIngredientWeightsIsPending,
  } =
    useMacroIngredientWeightAdjustmentScenariosMacroScenarioIdAdjustmentsIngredientsWeightPost(
      {
        mutation: {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey:
                getGetAllMacroScenarioAdjustmentsScenariosMacroScenarioIdAdjustmentsGetQueryKey(
                  parseInt(scenarioId),
                ),
            });

            navigateToAdjustments();

            showSnackbar(
              t("macro:adjustmentsPage.wizard.successMessage"),
              "success",
            );
          },
        },
      },
    );

  const {
    mutateAsync: editIngredientWeightsAdjustment,
    isPending: editIngredientWeightsIsPending,
  } =
    useEditMacroScenarioAdjustmentScenariosMacroScenarioIdAdjustmentsAdjustmentIdPatch(
      {
        mutation: {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey:
                getGetAllMacroScenarioAdjustmentsScenariosMacroScenarioIdAdjustmentsGetQueryKey(
                  parseInt(scenarioId),
                ),
            });

            queryClient.invalidateQueries({
              queryKey:
                getGetMacroAdjustmentByIdScenariosMacroScenarioIdAdjustmentsAdjustmentIdGetQueryKey(
                  parseInt(scenarioId),
                  adjustmentId as unknown as number,
                ),
            });

            navigateToAdjustments();

            showSnackbar(
              t("macro:adjustmentsPage.wizard.updateSuccessMessage"),
              "success",
            );
          },
        },
      },
    );

  const getSaveFunction = useCallback(() => {
    if (!adjustmentCombination) return undefined;

    switch (adjustmentCombination.combo) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        return adjustmentId
          ? editIngredientWeightsAdjustment
          : (createIngredientWeightsAdjustment as any);
      default:
        return undefined;
    }
  }, [
    adjustmentCombination,
    adjustmentId,
    createIngredientWeightsAdjustment,
    editIngredientWeightsAdjustment,
  ]);

  const getAdjustmentType = useCallback((): string | undefined => {
    if (!adjustmentCombination) return undefined;

    switch (adjustmentCombination.combo) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return "multi";
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        return "specific";
      default:
        return undefined;
    }
  }, [adjustmentCombination]);

  const getPayload = useCallback(
    (formikValues: any) => {
      if (!adjustmentCombination) return undefined;

      switch (adjustmentCombination.combo) {
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
          return {
            name: formikValues.payload.name,
            description: formikValues.payload.description,
            entities: formikValues.payload.entities.map((entity: any) => ({
              ...entity,
              action: formikValues.selectedAction,
            })),
            filters: {
              brand_groups: formikValues.payload.filters.brand_groups,
              brands: formikValues.payload.filters.brands,
              categories: formikValues.payload.filters.categories,
              countries: formikValues.payload.filters.countries,
            },
          } as CreateIngredientWeightMacroAdjustment;
        default:
          return undefined;
      }
    },
    [adjustmentCombination],
  );

  const handleSaveAdjustment = useCallback(
    async (formikValues: any) => {
      showGlobalLoader(true, true);
      try {
        const saveFunction: any = getSaveFunction();
        const type = getAdjustmentType();
        const payload = getPayload(formikValues);
        if (saveFunction) {
          if (!adjustmentId && type) {
            await saveFunction({
              scenarioId: scenarioId,
              data: payload,
              params: {
                adjustment_type: type,
              },
            });
          } else {
            await saveFunction({
              scenarioId: scenarioId,
              adjustmentId: adjustmentId,
              data: payload,
            });
          }
        }
      } catch (error: any) {
        showSnackbar(error, "error");
        console.warn(
          `Error ${adjustmentId ? "updating" : "creating"} adjustment: `,
          error,
        );
      } finally {
        showGlobalLoader(false);
      }
    },
    [
      showGlobalLoader,
      getSaveFunction,
      getAdjustmentType,
      adjustmentId,
      scenarioId,
      showSnackbar,
    ],
  );

  useEffect(() => {
    filtersDataIsPending ? showGlobalLoader(true) : showGlobalLoader(false);
  }, [filtersDataIsPending, showGlobalLoader]);

  const formikInitialValues: MacroAdjustmentWizardFormikValues = useMemo(
    () => ({
      payload: {
        description: adjustmentDetails?.description || "",
        entities:
          adjustmentDetails?.entities.map((entity) => ({
            ingredient_id: entity.ingredient_id as number,
            adjusted_value: entity.adjusted_value as number,
          })) || [],
        filters: {
          brand_groups:
            (adjustmentDetails?.filters.brand_groups as number[]) || [],
          brands: (adjustmentDetails?.filters.brands as number[]) || [],
          categories: (adjustmentDetails?.filters.categories as number[]) || [],
          countries: (adjustmentDetails?.filters.countries as string[]) || [],
        },
        name: adjustmentDetails?.name || "",
      },
      selectedPillar:
        (adjustmentDetails?.pillar as MacroAdjustmentPillars) || null,
      selectedInputArea:
        (adjustmentDetails?.input_area as MacroAdjustmentInputAreas) || null,
      selectedAdjustmentType:
        (adjustmentDetails?.adjustment_type as MacroAdjustmentTypes) || null,
      selectedCountries:
        (adjustmentDetails?.filters.countries as string[])?.map(
          (country) =>
            scenarioDetails?.inputs?.countries?.find(
              (c) => c.country === country,
            ) as CountryViewModel,
        ) || [],
      selectedEntities:
        (adjustmentDetails?.entities.map((entity) => {
          return filtersData?.filters.ingredients.find((ingredient) => {
            return ingredient.id === entity.ingredient_id;
          });
        }) as MacroFilter[]) || [],
      selectedAction: "percentage",
    }),
    [
      adjustmentDetails?.adjustment_type,
      adjustmentDetails?.description,
      adjustmentDetails?.entities,
      adjustmentDetails?.filters.brand_groups,
      adjustmentDetails?.filters.brands,
      adjustmentDetails?.filters.categories,
      adjustmentDetails?.filters.countries,
      adjustmentDetails?.input_area,
      adjustmentDetails?.name,
      adjustmentDetails?.pillar,
      filtersData?.filters,
      scenarioDetails?.inputs?.countries,
    ],
  );

  const updateAdjustmentCombination = useCallback(
    (
      selectedPillar: MacroAdjustmentPillars | null,
      selectedInputArea: MacroAdjustmentInputAreas | null,
      selectedAdjustmentType: MacroAdjustmentTypes | null,
    ) => {
      if (selectedPillar && selectedInputArea && selectedAdjustmentType) {
        const newSelectedInputArea = _.upperFirst(
          _.camelCase(selectedInputArea),
        );
        const newSelectedAdjustmentType = _.upperFirst(
          _.camelCase(selectedAdjustmentType),
        );
        const combination: MacroAdjustmentCombination = {
          combo:
            `${selectedPillar}_${newSelectedInputArea}_${newSelectedAdjustmentType}` as MacroAdjustmentCombinations,
          name: `${selectedPillar} - ${selectedInputArea} - ${selectedAdjustmentType}`,
        };
        setAdjustmentCombination(combination);
      } else {
        setAdjustmentCombination(null);
      }
    },
    [],
  );

  useEffect(() => {
    if (adjustmentDetails) {
      updateAdjustmentCombination(
        adjustmentDetails.pillar as MacroAdjustmentPillars,
        adjustmentDetails.input_area as MacroAdjustmentInputAreas,
        adjustmentDetails.adjustment_type as MacroAdjustmentTypes,
      );
    }
  }, [adjustmentDetails, updateAdjustmentCombination]);

  useEffect(() => {
    createIngredientWeightsIsPending || editIngredientWeightsIsPending
      ? showGlobalLoader(true, true)
      : showGlobalLoader(false);
  }, [
    createIngredientWeightsIsPending,
    editIngredientWeightsIsPending,
    showGlobalLoader,
  ]);

  useEffect(() => {
    if (filtersData && scenarioDetails) {
      setIsFormInitialized(true);
    }
  }, [filtersData, scenarioDetails]);

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={macroAdjustmentValidationSchema}
      enableReinitialize={!isFormInitialized}
      onSubmit={() => {}}
    >
      {(formikProps) => {
        return (
          <Form>
            <Box>
              <MacroAdjustmentsWizardHeaderAndStepper
                handleNavigateToAdjustmentsPage={
                  handleNavigateToAdjustmentsPage
                }
                formikProps={formikProps}
                headersIndent={headersIndent}
                activeStep={activeStep}
                steps={steps}
              ></MacroAdjustmentsWizardHeaderAndStepper>

              {/*IMPORTANT: This is the actual step component*/}
              <Box mb={4}>{steps(formikProps)[activeStep].component}</Box>

              <MacroAdjustmentsWizardFooter
                handleLeftButtonClick={handleLeftButtonClick}
                steps={steps}
                formikProps={formikProps}
                activeStep={activeStep}
                handleRightButtonClick={handleRightButtonClick}
                adjustmentCombination={adjustmentCombination?.combo || null}
                validationErrors={validationErrors}
              ></MacroAdjustmentsWizardFooter>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MacroAdjustmentWizard;
