export enum Icons {
  ADD = "Add",
  ARROW = "Arrow",
  CHEVRON_DOUBLE_RIGHT = "ChevronDoubleRight",
  DATABASE = "Database",
  DOWNLOAD_SIMPLE = "DownloadSimple",
  GROW_RESPONSE = "GrowRevenue",
  HOME = "Home",
  SETTINGS = "Settings",
  TOGGLE = "Toggle",
  CIRCLE_DELETE = "CircleDelete",
  EXCLAMATION = "Exclamation",
  CONFIRMED = "Confirmed",
  INFORMATION = "Information",
  HELP_OUTLINE = "HelpOutline",
}

export type IconName =
  | Icons.ADD
  | Icons.ARROW
  | Icons.CHEVRON_DOUBLE_RIGHT
  | Icons.DATABASE
  | Icons.DOWNLOAD_SIMPLE
  | Icons.GROW_RESPONSE
  | Icons.HOME
  | Icons.SETTINGS
  | Icons.TOGGLE
  | Icons.CIRCLE_DELETE
  | Icons.EXCLAMATION
  | Icons.CONFIRMED
  | Icons.INFORMATION
  | Icons.HELP_OUTLINE;

export type CustomIconProps = {
  name: IconName;
  width?: number | string | undefined;
  height?: number | string | undefined;
  fill?: string;
};
