import { FC, useCallback } from "react";
import {
  MacroAdjustmentCombinations,
  MacroAdjustmentWizardFormikValues,
  MacroFilterDropdownTypes,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";
import Grid from "@mui/material/Grid2";
import SelectDropdown from "components/common/SelectDropdown/SelectDropdown";
import {
  MacroFilter,
  MacroFilters,
  MacroFiltersAndFilteredRTDL,
  MacroFilterValue,
} from "orval/generated/models";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

const StepTwoFilterDropdowns: FC<{
  adjustmentCombination: MacroAdjustmentCombinations | undefined;
  filtersData: MacroFiltersAndFilteredRTDL | undefined;
  setMacroFiltersParams: Function;
}> = ({ adjustmentCombination, filtersData, setMacroFiltersParams }) => {
  const { t } = useTranslation("macro");
  const { values, setFieldValue } =
    useFormikContext<MacroAdjustmentWizardFormikValues>();

  const handleFiltersSave = useCallback(
    (selectedItems: MacroFilterValue[], dropdownType: keyof MacroFilters) => {
      const items = filtersData?.filters[dropdownType]
        .filter((item: MacroFilter) => selectedItems.includes(item.value))
        ?.map((item: MacroFilter) => item.id);
      if (items) {
        setFieldValue("payload.filters", {
          ...values.payload.filters,
          [dropdownType]: items,
        }).catch((error) => {
          console.warn(error);
        });

        setMacroFiltersParams((prev: any) => ({
          ...prev,
          [dropdownType]: items,
        }));
      }
    },
    [
      filtersData?.filters,
      setFieldValue,
      setMacroFiltersParams,
      values.payload.filters,
    ],
  );

  if (!adjustmentCombination) {
    return null;
  }

  switch (adjustmentCombination) {
    case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
      return (
        <>
          <Grid size={4}>
            <SelectDropdown
              listItems={
                filtersData?.filters.categories?.map(
                  (category: MacroFilter) => category.value,
                ) || []
              }
              savedSelectedItems={
                filtersData?.filters.categories
                  .filter((category: MacroFilter) =>
                    values.payload.filters.categories?.includes(category.id),
                  )
                  ?.map((category: MacroFilter) => category.value) || []
              }
              onSave={(selectedItems) =>
                handleFiltersSave(
                  selectedItems,
                  MacroFilterDropdownTypes.Categories,
                )
              }
              title={t(
                "adjustmentsPage.wizard.stepTwo.dropdownTitles.categories",
              )}
              compact
              disabled={values.selectedCountries.length === 0}
            />
          </Grid>
          <Grid size={4}>
            <SelectDropdown
              listItems={
                filtersData?.filters.brand_groups?.map(
                  (brandGroup: MacroFilter) => brandGroup.value,
                ) || []
              }
              savedSelectedItems={
                filtersData?.filters.brand_groups
                  .filter((brand_group: MacroFilter) =>
                    values.payload.filters.brand_groups?.includes(
                      brand_group.id,
                    ),
                  )
                  ?.map((brand_group: MacroFilter) => brand_group.value) || []
              }
              onSave={(selectedItems) =>
                handleFiltersSave(
                  selectedItems,
                  MacroFilterDropdownTypes.BrandGroups,
                )
              }
              title={t(
                "adjustmentsPage.wizard.stepTwo.dropdownTitles.brandGroups",
              )}
              compact
              disabled={values.selectedCountries.length === 0}
            />
          </Grid>
          <Grid size={4}>
            <SelectDropdown
              listItems={
                filtersData?.filters.brands?.map(
                  (brand: MacroFilter) => brand.value,
                ) || []
              }
              savedSelectedItems={
                filtersData?.filters.brands
                  .filter((brand: MacroFilter) =>
                    values.payload.filters.brands?.includes(brand.id),
                  )
                  ?.map((brand: MacroFilter) => brand.value) || []
              }
              onSave={(selectedItems) =>
                handleFiltersSave(
                  selectedItems,
                  MacroFilterDropdownTypes.Brands,
                )
              }
              title={t("adjustmentsPage.wizard.stepTwo.dropdownTitles.brands")}
              compact
              disabled={values.selectedCountries.length === 0}
            />
          </Grid>
        </>
      );

    case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
      return (
        <>
          <Grid size={4}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.categories")}`}
                  name={"category"}
                />
              )}
              value={
                filtersData?.filters.categories
                  .filter((category: MacroFilter) =>
                    values.payload.filters.categories?.includes(category.id),
                  )
                  ?.map((category: MacroFilter) => category.value)[0] || null
              }
              options={
                filtersData?.filters.categories?.map(
                  (category: MacroFilter) => category.value,
                ) || []
              }
              size={"small"}
              onChange={(_event, value) =>
                handleFiltersSave(
                  [value] as MacroFilterValue[],
                  MacroFilterDropdownTypes.Categories,
                )
              }
              clearOnBlur
              handleHomeEndKeys
              disabled={values.selectedCountries.length === 0}
            />
          </Grid>
          <Grid size={4}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.brandGroups")}`}
                  name={"brandGroups"}
                />
              )}
              value={
                filtersData?.filters.brand_groups
                  .filter((brandGroup: MacroFilter) =>
                    values.payload.filters.brand_groups?.includes(
                      brandGroup.id,
                    ),
                  )
                  ?.map((brandGroup: MacroFilter) => brandGroup.value)[0] ||
                null
              }
              options={
                filtersData?.filters.brand_groups?.map(
                  (brandGroup: MacroFilter) => brandGroup.value,
                ) || []
              }
              size={"small"}
              onChange={(_event, value) =>
                handleFiltersSave(
                  [value] as MacroFilterValue[],
                  MacroFilterDropdownTypes.BrandGroups,
                )
              }
              clearOnBlur
              handleHomeEndKeys
              disabled={values.selectedCountries.length === 0}
            />
          </Grid>
          <Grid size={4}>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.brands")}`}
                  name={"brands"}
                  required
                />
              )}
              value={
                filtersData?.filters.brands
                  .filter((brand: MacroFilter) =>
                    values.payload.filters.brands?.includes(brand.id),
                  )
                  ?.map((brand: MacroFilter) => brand.value)[0] || null
              }
              options={
                filtersData?.filters.brands?.map(
                  (brand: MacroFilter) => brand.value,
                ) || []
              }
              size={"small"}
              onChange={(_event, value) =>
                handleFiltersSave(
                  [value] as MacroFilterValue[],
                  MacroFilterDropdownTypes.Brands,
                )
              }
              clearOnBlur
              handleHomeEndKeys
              disabled={values.selectedCountries.length === 0}
            />
          </Grid>
        </>
      );
    default:
      return null;
  }
};

export default StepTwoFilterDropdowns;
