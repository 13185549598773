import { SelectDropdownListItem } from "components/common/SelectDropdown/SelectDropdownRow/SelectDropdownRow";
import {
  MacroAdjustmentInputAreas,
  MacroAdjustmentPillars,
  MacroAdjustmentTypes,
} from "./MacroAdjustmentTypes";

export const getPillars = <
  T extends string | number,
>(): SelectDropdownListItem<T>[] => [
  {
    title: MacroAdjustmentPillars.Ingredients,
    value: MacroAdjustmentPillars.Ingredients as unknown as T,
  },
  {
    title: MacroAdjustmentPillars.Packaging,
    value: MacroAdjustmentPillars.Packaging as unknown as T,
  },
  {
    title: MacroAdjustmentPillars.Manufacturing,
    value: MacroAdjustmentPillars.Manufacturing as unknown as T,
  },
  {
    title: MacroAdjustmentPillars.Logistics,
    value: MacroAdjustmentPillars.Logistics as unknown as T,
  },
  {
    title: MacroAdjustmentPillars.CDE,
    value: MacroAdjustmentPillars.CDE as unknown as T,
  },
  {
    title: MacroAdjustmentPillars.SalesMix,
    value: MacroAdjustmentPillars.SalesMix as unknown as T,
  },
];

export const filterInputAreas = (
  selectedPillar: MacroAdjustmentPillars | null | undefined,
  setInputAreaDropdownItems: (
    items: SelectDropdownListItem<string | number>[],
  ) => void,
) => {
  switch (selectedPillar) {
    case MacroAdjustmentPillars.Ingredients:
      setInputAreaDropdownItems([
        {
          title: MacroAdjustmentInputAreas.IngredientWeights,
          value: [
            {
              title: MacroAdjustmentTypes.MultiBrandAdjustment,
              value: MacroAdjustmentTypes.MultiBrandAdjustment,
            },
            {
              title: MacroAdjustmentTypes.SpecificBrandAdjustment,
              value: MacroAdjustmentTypes.SpecificBrandAdjustment,
            },
          ],
        },
        {
          title: MacroAdjustmentInputAreas.IngredientEmissionFactors,
          value: MacroAdjustmentInputAreas.IngredientEmissionFactors,
        },
      ]);
      break;
    case MacroAdjustmentPillars.Packaging:
      setInputAreaDropdownItems([
        {
          title: MacroAdjustmentInputAreas.PackagingWeights,
          value: [
            {
              title: MacroAdjustmentTypes.MultiSKUAdjustment,
              value: MacroAdjustmentTypes.MultiSKUAdjustment,
            },
            {
              title: MacroAdjustmentTypes.SpecificSKUAdjustment,
              value: MacroAdjustmentTypes.SpecificSKUAdjustment,
            },
          ],
        },
        {
          title: MacroAdjustmentInputAreas.RecycledContent,
          value: MacroAdjustmentInputAreas.RecycledContent,
        },
        {
          title: MacroAdjustmentInputAreas.RecoveryRate,
          value: MacroAdjustmentInputAreas.RecoveryRate,
        },
        {
          title: MacroAdjustmentInputAreas.NetLossRate,
          value: MacroAdjustmentInputAreas.NetLossRate,
        },
        {
          title: MacroAdjustmentInputAreas.PackagingEmissionFactors,
          value: MacroAdjustmentInputAreas.PackagingEmissionFactors,
        },
      ]);
      break;
    case MacroAdjustmentPillars.Manufacturing:
      setInputAreaDropdownItems([
        {
          title: MacroAdjustmentInputAreas.EnergyUsageRatio,
          value: MacroAdjustmentInputAreas.EnergyUsageRatio,
        },
        {
          title: MacroAdjustmentInputAreas.SourceMixAndEmissionFactors,
          value: MacroAdjustmentInputAreas.SourceMixAndEmissionFactors,
        },
      ]);
      break;
    case MacroAdjustmentPillars.Logistics:
      setInputAreaDropdownItems([
        {
          title: MacroAdjustmentInputAreas.DistanceTravelled,
          value: MacroAdjustmentInputAreas.DistanceTravelled,
        },
        {
          title: MacroAdjustmentInputAreas.RoadVehicleMixAndEmissionFactors,
          value: MacroAdjustmentInputAreas.RoadVehicleMixAndEmissionFactors,
        },
        {
          title: MacroAdjustmentInputAreas.OtherVehicleMixAndEmissionFactors,
          value: MacroAdjustmentInputAreas.OtherVehicleMixAndEmissionFactors,
        },
      ]);
      break;
    case MacroAdjustmentPillars.CDE:
      setInputAreaDropdownItems([
        {
          title: MacroAdjustmentInputAreas.CDEFleetBreakdown,
          value: MacroAdjustmentInputAreas.CDEFleetBreakdown,
        },
        {
          title: MacroAdjustmentInputAreas.CDEEnergyMixAndEmissionFactors,
          value: MacroAdjustmentInputAreas.CDEEnergyMixAndEmissionFactors,
        },
      ]);
      break;
    case MacroAdjustmentPillars.SalesMix:
      setInputAreaDropdownItems([
        {
          title: MacroAdjustmentInputAreas.SalesGrowth,
          value: MacroAdjustmentInputAreas.SalesGrowth,
        },
        {
          title: MacroAdjustmentInputAreas.CategoryMix,
          value: MacroAdjustmentInputAreas.CategoryMix,
        },
        {
          title: MacroAdjustmentInputAreas.PackMix,
          value: MacroAdjustmentInputAreas.PackMix,
        },
        {
          title: MacroAdjustmentInputAreas.DLZShare,
          value: MacroAdjustmentInputAreas.DLZShare,
        },
      ]);
      break;
    default:
      setInputAreaDropdownItems([]);
  }
};

export const setupAdjustmentTypeDropdown = (
  inputArea: MacroAdjustmentInputAreas,
  setAdjustmentDropdownItems: (
    items: SelectDropdownListItem<string | number>[],
  ) => void,
) => {
  switch (inputArea) {
    case MacroAdjustmentInputAreas.IngredientWeights:
      setAdjustmentDropdownItems([
        {
          title: MacroAdjustmentTypes.MultiBrandAdjustment,
          value: MacroAdjustmentTypes.MultiBrandAdjustment,
        },
        {
          title: MacroAdjustmentTypes.SpecificBrandAdjustment,
          value: MacroAdjustmentTypes.SpecificBrandAdjustment,
        },
      ]);
      break;
    case MacroAdjustmentInputAreas.PackagingWeights:
      setAdjustmentDropdownItems([
        {
          title: MacroAdjustmentTypes.MultiSKUAdjustment,
          value: MacroAdjustmentTypes.MultiSKUAdjustment,
        },
        {
          title: MacroAdjustmentTypes.SpecificSKUAdjustment,
          value: MacroAdjustmentTypes.SpecificSKUAdjustment,
        },
      ]);
      break;
    default:
      setAdjustmentDropdownItems([
        {
          title: MacroAdjustmentTypes.NotApplicable,
          value: MacroAdjustmentTypes.NotApplicable,
        },
      ]);
  }
};
