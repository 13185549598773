import i18next from "i18next";
import { formatNumber } from "utils/formatNumber/formatNumber";
import {
  Chart,
  ChartTableCategory,
  TableData,
  WaterfallBaseGlideScenario,
  WaterfallTableDataMetrics,
  Years,
  YearsBaseYear,
  YearsTargetYear,
} from "orval/generated/models";
import { AppConstant } from "constants/AppConstant";
import { ProductConfigurationsMacro } from "features/micro-tool/components/MicroAdjustmentsTable/types";

type chartData = {
  label: string;
  data: (number[] | null)[];
  backgroundColor: string;
}[];

function getDataArray(
  data: Chart,
  type: "base" | "glide" | "scenario",
  year: YearsBaseYear | YearsTargetYear | undefined,
  startingData: chartData,
  sectionType: "by_category" | "by_pack",
): chartData {
  const chartData: chartData = startingData;

  Object.keys(data).forEach((key, i) => {
    if (data[key as keyof Chart].length) {
      const nullValues = Array(chartData.length).fill(null);
      const dataSet = [data[key as keyof Chart]];
      const dataArray = nullValues.concat(dataSet);
      const isBaseData = type === "base";

      chartData.push({
        label: `${i === 0 ? year : ""} ${i18next.t(`macro:resultsSection.waterfall.chart.labels.${key === "mix" ? `${key}_${sectionType}` : key}${i === 0 ? (isBaseData ? "_base" : type === "glide" ? "_glide" : "_scenario") : ""}`)}`,
        data: dataArray,
        backgroundColor:
          key === "base_value"
            ? isBaseData
              ? "#CBCBCB"
              : type === "glide"
                ? "#979797"
                : "#D04A02"
            : data[key as keyof Chart][1] < data[key as keyof Chart][0]
              ? "#4AB523"
              : "#E0301E",
      });
    }
  });
  return chartData;
}

function getWaterfallData(
  data: ChartTableCategory,
  years: Years | undefined,
  type: "by_category" | "by_pack",
) {
  const baseToGlideChartData = getDataArray(
    data.base,
    "base",
    years?.base_year,
    [],
    type,
  );

  const glideToScenarioChartData = getDataArray(
    data.glide,
    "glide",
    years?.target_year,
    Array(baseToGlideChartData.length).fill({ "": [] }),
    type,
  );

  const scenarioChartData = getDataArray(
    data.scenario,
    "scenario",
    years?.target_year,
    Array(glideToScenarioChartData.length).fill({ "": [] }),
    type,
  );

  return [
    ...baseToGlideChartData,
    ...glideToScenarioChartData,
    ...scenarioChartData,
  ];
}

function getWaterfallLabels(
  data: ChartTableCategory,
  years: Years | undefined,
  type: "by_category" | "by_pack",
): string[] {
  const chartLabels: string[] = [];

  Object.keys(data.base).forEach((key, i) => {
    chartLabels.push(
      `${i === 0 ? years?.base_year : ""} ${i18next.t(`macro:resultsSection.waterfall.chart.labels.${key === "mix" ? `${key}_${type}` : key}${i === 0 ? "_base" : ""}`)}`,
    );
  });

  Object.keys(data.glide).forEach((key, i) => {
    if (data.glide[key as keyof Chart].length) {
      chartLabels.push(
        `${i === 0 ? years?.target_year : ""} ${i18next.t(`macro:resultsSection.waterfall.chart.labels.${key === "mix" ? `${key}_${type}` : key}${i === 0 ? "_glide" : ""}`)}`,
      );
    }
  });

  Object.keys(data.scenario).forEach((key, i) => {
    if (data.scenario[key as keyof Chart].length) {
      chartLabels.push(
        `${i === 0 ? years?.target_year : ""} ${i18next.t(`macro:resultsSection.waterfall.chart.labels.${key === "mix" ? `${key}_${type}` : key}${i === 0 ? "_scenario" : ""}`)}`,
      );
    }
  });

  return chartLabels;
}

function getTableData(data: TableData | undefined, type: keyof TableData) {
  if (!data) {
    return [];
  }

  return Object.keys(data[type]).map((key) => {
    const values = data[type][key as keyof WaterfallTableDataMetrics];
    const valuesObj: { [key: string]: string } = {};

    Object.keys(values).forEach((valueKey) => {
      const value = formatNumber(
        values[valueKey as keyof WaterfallBaseGlideScenario],
        ProductConfigurationsMacro.PERCENTAGE,
      );
      valuesObj[valueKey] =
        value === AppConstant.emptyCell ? value : `${value} %`;
    });

    return {
      metric: i18next.t(
        `macro:resultsSection.waterfall.chart.labels.${key === "category_mix" ? `mix_${type}` : key}`,
      ),
      ...valuesObj,
    };
  });
}

export { getWaterfallData, getWaterfallLabels, getTableData, getDataArray };
