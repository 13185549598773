import {
  ProductConfigurationsMacro,
  ProductConfigurationsMicro,
} from "features/micro-tool/components/MicroAdjustmentsTable/types";
import { AppConstant } from "constants/AppConstant";
import { OptionalMetricsTableResultsFilterDataType } from "features/macro-tool/components/MacroOptionalMetricsTable/Types";

export function formatNumber(
  value: number | null | undefined | string,
  dataStructureKey?: string,
  parentAccordion?: string,
): string {
  if (value === null || value === undefined || value === "") {
    return AppConstant.emptyCell; // Return null if the value is null or undefined
  }

  dataStructureKey = parentAccordion ?? dataStructureKey;

  let numberToFormat: number;
  if (typeof value === "string") {
    numberToFormat = parseFloat(value);
  } else {
    numberToFormat = value;
  }

  const locale = Intl.DateTimeFormat().resolvedOptions().locale || "en-GB"; // Detect the locale from the browser

  let decimalPlaces: number;
  switch (dataStructureKey) {
    case ProductConfigurationsMacro.WATERFALL:
    case ProductConfigurationsMicro.SERVING_SIZE:
    case ProductConfigurationsMacro.ABSOLUTE:
    case OptionalMetricsTableResultsFilterDataType.ABSOLUTE:
    case ProductConfigurationsMacro.SALES_VOLUME_BAR_ZERO_DP:
    case ProductConfigurationsMacro.ZERO_DP:
      decimalPlaces = 0;
      break;
    case ProductConfigurationsMicro.INGREDIENTS:
    case ProductConfigurationsMicro.BODY_AND_LID_PACKAGING:
    case ProductConfigurationsMicro.LOGISTICS:
    case ProductConfigurationsMicro.OTHER_PACKAGING:
    case ProductConfigurationsMacro.PER_LITRE:
    case ProductConfigurationsMacro.PERCENTAGE:
    case ProductConfigurationsMacro.SALES:
    case ProductConfigurationsMacro.MEKKO:
    case ProductConfigurationsMicro.RESULTS:
    case OptionalMetricsTableResultsFilterDataType.PER_LITRE:
    case ProductConfigurationsMacro.SALES_VOLUME_BAR_ONE_DP:
    case ProductConfigurationsMacro.ONE_DP:
      decimalPlaces = 1;
      break;
    case ProductConfigurationsMacro.TWO_DP:
      decimalPlaces = 2;
      break;
    case ProductConfigurationsMicro.INGREDIENTS_EMISSION_FACTORS:
    case ProductConfigurationsMicro.BODY_AND_LID_EMISSION_FACTORS:
    case ProductConfigurationsMicro.MANUFACTURING:
    case ProductConfigurationsMicro.OTHER_PACKAGING_EMISSION_FACTORS:
    case ProductConfigurationsMacro.THREE_DP:
      decimalPlaces = 3;
      break;
    case ProductConfigurationsMacro.FOUR_DP:
      decimalPlaces = 4;
      break;
    default:
      // Taken from numberParser.ts to get decimal symbol location
      const format = new Intl.NumberFormat(locale);
      const parts = format.formatToParts(-12345.6);
      const decimalPart = parts.find((d) => d.type === "decimal");
      const decimalRegExp = new RegExp(`[${decimalPart?.value}]`);
      const indexInArr: number = value
        .toString()
        .split("")
        .findIndex((element: string) => decimalRegExp.test(element));

      if (indexInArr === -1) {
        decimalPlaces = 0;
      } else {
        decimalPlaces = value.toString().length - indexInArr - 1;
      }
  }

  const formatedNumber = numberToFormat.toLocaleString(locale, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });

  const negativeZeroPattern = /^-0(\.0+)?$/;
  return negativeZeroPattern.test(formatedNumber)
    ? formatedNumber.replace("-", "")
    : formatedNumber;
}

export function formatNumberOrEmpty(
  value: number | null | undefined | string,
  dataStructureKey?: string,
): string {
  return value !== 0
    ? formatNumber(value, dataStructureKey)
    : AppConstant.emptyCell;
}
