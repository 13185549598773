import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import { MacroOptionalMetricsTable } from "../MacroOptionalMetricsTable/MacroOptionalMetricsTable";
import { ViewMacroScenarioViewModel } from "orval/generated/models";
import { OptionalMetricsTableResultsType } from "../MacroOptionalMetricsTable/Types";
import getResultsOverviewTableColumns from "../MacroOptionalMetricsTable/HelperFunctions/getResultsOverviewTableColumns";
import getResultsOverviewTableData from "../MacroOptionalMetricsTable/HelperFunctions/getResultsOverviewTableData";
import getResultsOverviewSalesTableData from "../MacroOptionalMetricsTable/HelperFunctions/getResultsOverviewSalesTableData";

export type CarbonEmissionsBySectionProps = {
  scenario: ViewMacroScenarioViewModel | null;
  type: OptionalMetricsTableResultsType;
};

export function CarbonEmissionsBySection({
  scenario,
  type,
}: CarbonEmissionsBySectionProps) {
  const { t } = useTranslation("macro");
  const tempColumns = getResultsOverviewTableColumns(
    type,
    scenario?.inputs?.years?.base_year?.toString() || "",
    scenario?.inputs?.years?.target_year?.toString() || "",
  );

  return (
    <>
      <Grid container rowSpacing={3} columnSpacing={3}>
        <Grid
          size={{
            md: 12,
            lg: type !== OptionalMetricsTableResultsType.BY_PILLAR ? 6 : 12,
          }}
        >
          <MacroOptionalMetricsTable
            columns={tempColumns}
            data={scenario}
            dataExtractionFunction={getResultsOverviewTableData}
            type={type}
            displaySubtitle={true}
            title={t(
              "resultsSection.carbonEmissionOverview.tableHeaders.absoluteEmission",
            )}
            showFilterDropdown
          />
        </Grid>
        {type !== OptionalMetricsTableResultsType.BY_PILLAR && (
          <Grid size={{ md: 12, lg: 6 }}>
            <MacroOptionalMetricsTable
              columns={tempColumns}
              data={scenario}
              dataExtractionFunction={getResultsOverviewSalesTableData}
              type={type}
              subtitle={t(
                "resultsSection.carbonEmissionOverview.filters.units.sales_volume",
              )}
              displaySubtitle={true}
              title={t(
                "resultsSection.carbonEmissionOverview.tableHeaders.salesVolume",
              )}
              showFilterDropdown={false}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
