import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import React from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  MacroAdjustmentStep,
  MacroAdjustmentWizardFormikValues,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";

export type MacroAdjustmentsWizardHeaderAndStepperProps = {
  handleNavigateToAdjustmentsPage: (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => void;
  formikProps: FormikProps<MacroAdjustmentWizardFormikValues>;
  headersIndent: string;
  activeStep: number;
  steps: (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => MacroAdjustmentStep[];
};

const MacroAdjustmentsWizardHeaderAndStepper = ({
  handleNavigateToAdjustmentsPage,
  formikProps,
  headersIndent,
  activeStep,
  steps,
}: MacroAdjustmentsWizardHeaderAndStepperProps) => {
  const { t } = useTranslation("macro");
  return (
    <Box>
      <Box>
        <Button
          variant="text"
          onClick={() => handleNavigateToAdjustmentsPage(formikProps)}
          startIcon={<ArrowBackIosSharpIcon />}
        >
          {t("macro:adjustmentsPage.wizard.backToAdjustmentsPage")}
        </Button>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        justify-content={"center"}
        mb={2}
      >
        {steps(formikProps)[activeStep].stepTitle && (
          <Box>
            <Typography
              className="header-H2 text-ellipsis"
              sx={{
                paddingX: headersIndent,
                transition: "padding 0.3s ease-in-out",
              }}
            >
              {steps(formikProps)[activeStep].stepTitle}
            </Typography>
          </Box>
        )}

        {steps(formikProps)[activeStep].stepSubtitle && (
          <Box display={"flex"} alignItems={"center"}>
            <Box>
              <FiberManualRecordIcon
                sx={{
                  m: 1,
                  fontSize: "5px",
                  color: "grey",
                }}
              />
            </Box>
            <Box>
              <Typography
                className="header-H3 text-ellipsis"
                sx={{
                  paddingX: headersIndent,
                  transition: "padding 0.3s ease-in-out",
                }}
              >
                {steps(formikProps)[activeStep].stepSubtitle}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box mb={4}>
        <Stepper activeStep={activeStep}>
          {Array.isArray(steps(formikProps)) &&
            steps(formikProps).map((step) => (
              <Step key={step.index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
        </Stepper>
      </Box>
    </Box>
  );
};

export default MacroAdjustmentsWizardHeaderAndStepper;
