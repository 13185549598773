import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import MicroEmissionsChart from "../MicroEmissionsChart/MicroEmissionsChart";
import { ProductColumnData } from "../../types";
import { MRT_ColumnDef } from "material-react-table";
import { NoData } from "components/common";
import { MicroResultsTable } from "../MicroResultsTable/MicroResultsTable";
import { CustomSelect } from "components/common/CustomSelect";
import { Results, ViewMicroScenario } from "orval/generated/models";
import {
  COLUMN_WIDTH,
  MICRO_SCENARIO_LOCAL_STORAGE_FILTER_PREFIX,
} from "../../constants";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AccordionSummary from "@mui/material/AccordionSummary";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import getResultsTableData from "../MicroResultsTable/HelperService";
import { DisplayOption } from "../types";

export type MicroResultsSectionProps = {
  disabled: boolean;
  products: ProductColumnData[] | undefined;
  scenario: ViewMicroScenario | undefined;
  columns: MRT_ColumnDef<any>[];
};

type SavedMicroFilter = {
  unit: string;
  hideCDE: boolean;
  displayOption: DisplayOption;
};

export function MicroResultsSection({
  disabled,
  products,
  scenario,
  columns,
}: MicroResultsSectionProps) {
  const savedMicroFilters: SavedMicroFilter = JSON.parse(
    localStorage.getItem(
      `${MICRO_SCENARIO_LOCAL_STORAGE_FILTER_PREFIX}${scenario?.id}`,
    ) || `{"unit": "per_litre","hideCDE": true,"displayOption": "Both"}`,
  );
  const { t } = useTranslation(["micro", "common"]);
  const [data, setData] = useState<any>();
  const [adjustedData, setAdjustedData] = useState<any>(""); //data after adjusments
  const [unit, setUnit] = useState<string>("");
  const [displayOption, setDisplayOption] = useState<DisplayOption>("Both");
  const [hideCDE, setHideCDE] = useState<boolean>(true);
  const [list, setList] = useState<string[]>([]);
  const { palette } = useTheme();

  const setDataObjects = useCallback(
    (
      products: ProductColumnData[],
      scenario: ViewMicroScenario,
      unit: string,
      hideCDE: boolean,
    ) => {
      setData(
        scenario.products &&
          getResultsTableData(
            products,
            scenario.products,
            unit as keyof Results,
            false,
            hideCDE,
          ),
      );
      setAdjustedData(
        scenario.products &&
          getResultsTableData(
            products,
            scenario.products,
            unit as keyof Results,
            true,
            hideCDE,
          ),
      );
    },
    [],
  );

  useEffect(() => {
    if (products?.length && scenario && unit) {
      setDataObjects(products, scenario, unit, hideCDE);
    }
  }, [products, scenario, unit, setDataObjects, hideCDE]);

  useEffect(() => {
    if (products?.length && scenario) {
      if (scenario.products?.[0]?.results?.base) {
        const results = scenario.products?.[0]?.results?.base;
        if (results) {
          const keys = Object.keys(results);
          savedMicroFilters.unit
            ? setUnit(savedMicroFilters.unit)
            : setUnit(keys[0]);
          setList(keys);
        }
      }
    }
  }, [products, scenario, savedMicroFilters.unit]);

  useEffect(() => {
    if (savedMicroFilters.displayOption) {
      setDisplayOption(savedMicroFilters.displayOption);
    }
    if (
      savedMicroFilters.hideCDE !== null &&
      savedMicroFilters.hideCDE !== undefined
    ) {
      setHideCDE(savedMicroFilters.hideCDE);
    }
  }, [savedMicroFilters.displayOption, savedMicroFilters.hideCDE]);

  //This line is to add a column for the Co2 unit between the metric column and the products coulmns
  const resultColumns = columns
    ? [
        ...columns.slice(0, 1),
        {
          accessorKey: "unit",
          id: "unit",
          header: "",
          size: COLUMN_WIDTH,
          grow: false,
        },
        ...columns.slice(1),
      ]
    : [];

  function handleChangeUnit(value: string) {
    if (scenario?.id) {
      const newItem: SavedMicroFilter = {
        unit: value,
        hideCDE: hideCDE,
        displayOption: displayOption,
      };
      localStorage.setItem(
        `${MICRO_SCENARIO_LOCAL_STORAGE_FILTER_PREFIX}${scenario?.id}`,
        JSON.stringify(newItem),
      );
    }
    setUnit(value);
  }

  function handleChangeDisplayOption(
    event: React.MouseEvent<HTMLElement>,
    displayOption: DisplayOption,
  ) {
    if (scenario?.id) {
      const newItem: SavedMicroFilter = {
        unit: unit,
        hideCDE: hideCDE,
        displayOption: displayOption,
      };
      localStorage.setItem(
        `${MICRO_SCENARIO_LOCAL_STORAGE_FILTER_PREFIX}${scenario?.id}`,
        JSON.stringify(newItem),
      );
    }
    setDisplayOption(displayOption);
  }

  function handleCDEToggle(e: boolean) {
    if (products && scenario) {
      if (scenario?.id) {
        const newItem: SavedMicroFilter = {
          unit: unit,
          hideCDE: !e,
          displayOption: displayOption,
        };
        localStorage.setItem(
          `${MICRO_SCENARIO_LOCAL_STORAGE_FILTER_PREFIX}${scenario?.id}`,
          JSON.stringify(newItem),
        );
      }
      setHideCDE(!e);
      setDataObjects(products, scenario, unit, !e);
    }
  }

  console.log("savedMicroFilters", savedMicroFilters);

  return (
    <Accordion disabled={disabled}>
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        className="dropdown-left"
      >
        <Typography className="header-H4-bold">
          {t("resultsSection.title")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display={"flex"} flexDirection={"column"} gap={"24px"}>
          <>
            <Box
              border={"1px #CBCBCB solid"}
              padding={2}
              borderRadius={2}
              display={"flex"}
              flexDirection={"row"}
              gap={2}
              marginTop={2}
              marginLeft={1}
              marginRight={1}
            >
              <Box width={"50%"}>
                <Typography className="header-H4">
                  {t("common:filters")}
                </Typography>
                <Box display={"flex"} flexDirection={"row"} gap={2}>
                  <Box marginTop={"24px"} width={"400px"}>
                    {list.length && (
                      <CustomSelect
                        list={list}
                        label={t("resultsSection.units.label")}
                        source="micro"
                        sourceKey="resultsSection.units"
                        handlerFunction={handleChangeUnit}
                        initialValue={savedMicroFilters.unit}
                      />
                    )}
                  </Box>
                </Box>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 1 }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!hideCDE}
                          onChange={(e) => {
                            handleCDEToggle(e?.target?.checked);
                          }}
                        />
                      }
                      label={t("resultsSection.cde.label")}
                      labelPlacement="start"
                    />
                  </FormGroup>
                  <Tooltip title={t("resultsSection.cde.tooltip")}>
                    <ErrorOutlineIcon
                      sx={{
                        color: `${palette.primary.main}`,
                        transform: "rotate(180deg)",
                        fontSize: "18px",
                        margin: 1,
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
              <Box borderLeft={"1px solid #CBCBCB"} paddingLeft={2}>
                <Typography className="header-H4">
                  {t("resultsSection.displayOptions.title")}
                </Typography>
                <ToggleButtonGroup
                  value={displayOption}
                  exclusive
                  onChange={handleChangeDisplayOption}
                  aria-label="text alignment"
                  sx={{ marginTop: "24px" }}
                >
                  <ToggleButton
                    value="Both"
                    aria-label={t("resultsSection.displayOptions.both")}
                    className="banner-config-toggle"
                  >
                    {t("resultsSection.displayOptions.both")}
                  </ToggleButton>
                  <ToggleButton
                    value="Original Only"
                    aria-label={t("resultsSection.displayOptions.originalOnly")}
                    className="banner-config-toggle"
                  >
                    {t("resultsSection.displayOptions.originalOnly")}
                  </ToggleButton>
                  <ToggleButton
                    value="Adjusted Only"
                    aria-label={t("resultsSection.displayOptions.adjustedOnly")}
                    className="banner-config-toggle"
                  >
                    {t("resultsSection.displayOptions.adjustedOnly")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
            {!products || products?.length === 0 ? (
              <Box mt={2}>
                <NoData
                  label={t("micro:productsSection.noDataMessage")}
                  actionButtonLabel={t(
                    "micro:productsSection.noDataActionLabel",
                  )}
                ></NoData>
              </Box>
            ) : (
              <>
                {products?.length &&
                  data?.length &&
                  columns?.length &&
                  (displayOption === "Both" ||
                    displayOption === "Original Only") && (
                    <>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography className="header-H4-bold">
                          {t("resultsSection.withoutAdjustments")}
                        </Typography>
                      </Box>
                      <Box marginBottom={2}>
                        <MicroResultsTable
                          data={data}
                          columns={resultColumns}
                        ></MicroResultsTable>
                      </Box>
                    </>
                  )}

                {products?.length &&
                  adjustedData?.length &&
                  columns?.length &&
                  (displayOption === "Both" ||
                    displayOption === "Adjusted Only") && (
                    <>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography className="header-H4-bold">
                          {t("resultsSection.withAdjustments")}
                        </Typography>
                      </Box>
                      <Box>
                        <MicroResultsTable
                          data={adjustedData}
                          columns={resultColumns}
                        ></MicroResultsTable>
                      </Box>
                    </>
                  )}
                {scenario?.id && list?.length && (
                  <Box mt={4}>
                    <MicroEmissionsChart
                      chartsData={scenario}
                      hideCDE={hideCDE}
                      unit={unit}
                      displayOption={displayOption}
                    />
                  </Box>
                )}
              </>
            )}
          </>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
