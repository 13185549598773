import Box from "@mui/material/Box";
import { Autocomplete, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectDropdownListItem } from "components/common/SelectDropdown/SelectDropdownRow/SelectDropdownRow";
import { useFormikContext } from "formik";
import {
  MacroAdjustmentPillars,
  MacroAdjustmentInputAreas,
  MacroAdjustmentTypes,
  MacroAdjustmentWizardFormikValues,
} from "../MacroAdjustmentTypes";
import {
  getPillars,
  filterInputAreas,
  setupAdjustmentTypeDropdown,
} from "../HelperFunctions";

export type MacroAdjustmentStepOneProps = {
  updateAdjustmentCombination: Function;
  isEditWizard: boolean;
};

export const MacroAdjustmentStepOne = ({
  updateAdjustmentCombination,
  isEditWizard,
}: MacroAdjustmentStepOneProps) => {
  const { t } = useTranslation("macro");
  const { setFieldValue, values, handleChange, handleBlur } =
    useFormikContext<MacroAdjustmentWizardFormikValues>();

  const [inputAreaDropdownItems, setInputAreaDropdownItems] = useState<
    SelectDropdownListItem<any>[]
  >([]);
  const [adjustmentDropdownItems, setAdjustmentDropdownItems] = useState<
    SelectDropdownListItem<any>[]
  >([]);

  useEffect(() => {
    if (values.selectedPillar) {
      filterInputAreas(
        values.selectedPillar as MacroAdjustmentPillars,
        setInputAreaDropdownItems,
      );
    }
  }, [values.selectedPillar]);

  useEffect(() => {
    if (values.selectedInputArea) {
      setupAdjustmentTypeDropdown(
        values.selectedInputArea as MacroAdjustmentInputAreas,
        setAdjustmentDropdownItems,
      );
    }
  }, [values.selectedInputArea]);

  const pillars = useMemo(() => getPillars(), []);

  const handlePillarSelection = async (
    newValue: MacroAdjustmentPillars | null,
  ) => {
    setInputAreaDropdownItems([]);
    setAdjustmentDropdownItems([]);
    if (!newValue) {
      await setFieldValue("selectedPillar", null);
      await setFieldValue("selectedInputArea", null);
      await setFieldValue("selectedAdjustmentType", null);
    }
    if (newValue) {
      filterInputAreas(newValue, setInputAreaDropdownItems);
      await setFieldValue("selectedPillar", newValue);
    }
  };

  const handleInputAreaSelection = async (
    newValue: MacroAdjustmentInputAreas | null,
  ) => {
    if (!newValue) {
      await setFieldValue("selectedInputArea", null);
      await setFieldValue("selectedAdjustmentType", null);
    }
    if (newValue) {
      setupAdjustmentTypeDropdown(newValue, setAdjustmentDropdownItems);
      await setFieldValue("selectedInputArea", newValue);
    }
  };

  const handleAdjustmentTypeSelection = async (newValue: string | null) => {
    await setFieldValue("selectedAdjustmentType", newValue);
    updateAdjustmentCombination(
      values.selectedPillar as MacroAdjustmentPillars,
      values.selectedInputArea as MacroAdjustmentInputAreas,
      newValue as MacroAdjustmentTypes,
    );
  };

  return (
    <Box>
      <Box mb={1}>
        <Typography className="header-H2 text-ellipsis">
          {t("adjustmentsPage.wizard.stepOne.title")}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          {t("adjustmentsPage.wizard.stepOne.description")}
        </Typography>
      </Box>
      <Grid container spacing={2} mb={2}>
        <Grid size={4}>
          <TextField
            variant={"outlined"}
            size={"small"}
            name="payload.name"
            label={t(
              "macro:adjustmentsPage.wizard.stepOne.adjustmentNameInputTitle",
            )}
            placeholder={t(
              "macro:adjustmentsPage.wizard.stepOne.adjustmentNameInputPlaceholder",
            )}
            value={values.payload.name}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            data-testid={"adjustment-name-input"}
            fullWidth
          />
        </Grid>
      </Grid>
      <Box mb={2}>
        <TextField
          fullWidth
          name="payload.description"
          label={t(
            "macro:adjustmentsPage.wizard.stepOne.adjustmentNameDescriptionInputTitle",
          )}
          placeholder={t(
            "macro:adjustmentsPage.wizard.stepOne.adjustmentNameDescriptionInputPlaceholder",
          )}
          multiline
          rows={3}
          value={values.payload.description}
          onChange={handleChange}
          onBlur={handleBlur}
          data-testid={"adjustment-description-input"}
        />
      </Box>
      <Box mb={2}>
        <Typography>
          {t("adjustmentsPage.wizard.stepOne.descriptionTwo")}
        </Typography>
      </Box>
      <Grid container alignItems="center" spacing={2}>
        <Grid size={4}>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(
                  "macro:adjustmentsPage.wizard.stepOne.pillarSelection",
                )}
                name={"selectedPillar"}
              />
            )}
            value={values.selectedPillar}
            options={pillars.map((pillar) => pillar.title)}
            size={"small"}
            onChange={async (
              _event: SyntheticEvent,
              newValue: string | null | undefined,
            ) => {
              if (newValue === undefined) {
                return;
              }
              await handlePillarSelection(newValue as MacroAdjustmentPillars);
            }}
            clearOnBlur
            handleHomeEndKeys
            data-testid={"pillar-selection"}
            fullWidth
            getOptionDisabled={(option) =>
              option !== MacroAdjustmentPillars.Ingredients
            }
            disabled={isEditWizard}
          />
        </Grid>
        <Grid size={4}>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(
                  "macro:adjustmentsPage.wizard.stepOne.inputAreaSelection",
                )}
                name={"selectedInputArea"}
              />
            )}
            value={values.selectedInputArea}
            options={inputAreaDropdownItems.map((item) => item.title)}
            size={"small"}
            onChange={async (_event, value) => {
              if (value === undefined) {
                return;
              }
              await handleInputAreaSelection(
                value as MacroAdjustmentInputAreas,
              );
            }}
            clearOnBlur
            handleHomeEndKeys
            data-testid={"input-area-selection"}
            disabled={!values.selectedPillar || isEditWizard}
            getOptionDisabled={(option) =>
              option !== MacroAdjustmentInputAreas.IngredientWeights
            }
          />
        </Grid>
        <Grid size={4}>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label={t(
                  "macro:adjustmentsPage.wizard.stepOne.adjustmentTypeSelection",
                )}
                name={"selectedAdjustmentType"}
              />
            )}
            value={values.selectedAdjustmentType}
            options={adjustmentDropdownItems.map((item) => item.title)}
            size={"small"}
            onChange={async (_event, value) => {
              if (value === undefined) {
                return;
              }
              await handleAdjustmentTypeSelection(value);
            }}
            clearOnBlur
            handleHomeEndKeys
            data-testid={"adjustment-type-selection"}
            getOptionDisabled={(option) =>
              option === MacroAdjustmentTypes.SpecificBrandAdjustment
            }
            disabled={
              ![
                MacroAdjustmentInputAreas.IngredientWeights,
                MacroAdjustmentInputAreas.PackagingWeights,
              ].includes(
                values.selectedInputArea as MacroAdjustmentInputAreas,
              ) || isEditWizard
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
