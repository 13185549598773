import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import {
  getWaterfallData,
  getWaterfallLabels,
  getTableData,
} from "./HelperService";
import { MacroOptionalMetricsTable } from "../MacroOptionalMetricsTable/MacroOptionalMetricsTable";
import { ViewMacroScenarioViewModel } from "orval/generated/models";
import { TableResultsType } from "../ScenarioResults/types";
import WaterfallChart from "./WaterfallChart";

export type WaterfallChartSectionProps = {
  scenario: ViewMacroScenarioViewModel | null;
  sectionType: TableResultsType;
};

type chartData = {
  label: string;
  data: (number[] | null)[];
  backgroundColor: string;
}[];

function WaterfallChartSection({
  sectionType,
  scenario,
}: WaterfallChartSectionProps) {
  const { t } = useTranslation("macro");
  const [labels, setLabels] = useState<string[] | []>([]);
  const [dataSet, setDataSet] = useState<chartData>([]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "metric",
        header: t("resultsSection.waterfall.table.pillar"),
        id: "metric",
        size: 200,
        maxSize: 200,
        grow: false,
      },
      {
        accessorKey: "base_to_glide_path",
        header: t("resultsSection.waterfall.table.baseToGlidePath"),
        id: "base_to_glide_path",
      },
      {
        accessorKey: "glide_path_to_scenario",
        header: t("resultsSection.waterfall.table.glidePathToScenario"),
        id: "glide_path_to_scenario",
      },
      {
        accessorKey: "base_to_glide_path_per_litre_change",
        header: t("resultsSection.waterfall.table.baseToGlidePerLitreChange"),
        id: "base_to_glide_path_per_litre_change",
      },
      {
        accessorKey: "glide_path_to_scenario_per_litre_change",
        header: t(
          "resultsSection.waterfall.table.glideToScenarioPerLitreChange",
        ),
        id: "glide_path_to_scenario_per_litre_change",
      },
    ],
    [t],
  );

  const convertedType =
    sectionType === TableResultsType.BY_CATEGORY ? "by_category" : "by_pack";

  useEffect(() => {
    if (scenario && scenario?.results?.waterfall.chart) {
      const selectedData = scenario?.results?.waterfall.chart[convertedType];
      if (selectedData) {
        setDataSet(
          getWaterfallData(
            selectedData,
            scenario?.inputs?.years,
            convertedType,
          ),
        );
        setLabels(
          getWaterfallLabels(
            selectedData,
            scenario?.inputs?.years,
            convertedType,
          ),
        );
      }
    }
  }, [scenario, convertedType]);

  return (
    <>
      <Box
        gap="48px"
        display="flex"
        flexDirection="column"
        data-testid="waterfall-section"
      >
        <Box mt={2}>
          <WaterfallChart
            labels={labels}
            datasets={dataSet}
            title={t("resultsSection.waterfall.chartTitle")}
            subtitle={t("resultsSection.waterfall.chartSubtitle")}
          />
        </Box>
        <Box mb={4}>
          <MacroOptionalMetricsTable
            columns={columns}
            data={getTableData(
              scenario?.results?.waterfall.table,
              convertedType,
            )}
            displaySubtitle
            highlightLastColumn={false}
            title={t("resultsSection.waterfall.table.title")}
            subtitle={t("resultsSection.waterfall.table.subTitle")}
            highlightScenarioColumn
          />
        </Box>
      </Box>
    </>
  );
}

export default WaterfallChartSection;
