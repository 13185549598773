import { MoreVert } from "@mui/icons-material";
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import React, { SyntheticEvent, useEffect, useRef, useState } from "react";

export interface ThreeDotMenuProps {
  menuItems?: ThreeDotMenuItem[];
  disabled?: boolean;
}

export type ThreeDotMenuItem = {
  label: string;
  onClick: (event: Event | SyntheticEvent) => void;
  disabled?: boolean;
  id?: number;
};

const ThreeDotMenu = (props: ThreeDotMenuProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  function handleOnClick(event: React.MouseEvent, onClickFunction: Function) {
    onClickFunction(event);
    setOpen(false);
  }

  const wasPreviouslyOpen = useRef(open);

  useEffect(() => {
    if (wasPreviouslyOpen.current && !open) {
      anchorRef.current!.focus();
    }

    wasPreviouslyOpen.current = open;
  }, [open]);

  return (
    <div>
      <IconButton
        ref={anchorRef}
        id="composition-button"
        data-testid="menu-button"
        aria-label="Menu"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{ aspectRatio: 1 }}
        color="primary"
        disabled={props.disabled}
      >
        <MoreVert />
      </IconButton>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
          sx={{ zIndex: 20 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper sx={{ zIndex: 20 }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{ zIndex: 2 }}
                  >
                    {props.menuItems?.map((item) => {
                      return (
                        <MenuItem
                          key={item.label}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleOnClick(event, item.onClick);
                          }}
                          disabled={item.disabled}
                          id={item?.id?.toString()}
                          data-testid={`three-dot-menu-item-${item.id}`}
                          sx={{ height: "43px" }}
                        >
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </div>
  );
};

export default ThreeDotMenu;
