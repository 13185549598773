import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MRT_ColumnDef,
  MRT_TableContainer as MaterialTable,
  useMaterialReactTable,
} from "material-react-table";
import { useFormikContext } from "formik";
import { AppConstant } from "constants/AppConstant";
import {
  MacroAdjustmentCombinations,
  MacroAdjustmentWizardFormikValues,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";
import { ViewMacroScenarioViewModel } from "orval/generated/models";
import { ProductConfigurationsMacro } from "features/micro-tool/components/MicroAdjustmentsTable/types";
import { formatNumber } from "utils/formatNumber/formatNumber";
import { Loader } from "components/common";
import { OnChangeFn, VisibilityState } from "@tanstack/table-core";
import { useGetAggregatedIngredientsWeightScenariosMacroAdjustmentsAggregatedIngredientsWeightGet } from "orval/generated/endpoint";
import { CustomSelect } from "components/common/CustomSelect";

type TableEntityType = {
  ingredient_id: number;
  baseYear?: number;
  targetYear?: number;
  adjusted_value: number | null;
  action: "percentage" | "new_value";
};

export type MacroAdjustmentStepThreeProps = {
  validationErrors: Record<number, string | undefined>;
  setValidationErrors: (
    errors: (prev: Record<number, string | undefined>) => {
      [p: number]: string | undefined;
    },
  ) => void;
  scenario: ViewMacroScenarioViewModel | undefined;
  adjustmentCombination: MacroAdjustmentCombinations | undefined;
};

export const MacroAdjustmentStepThree = ({
  validationErrors,
  setValidationErrors,
  scenario,
  adjustmentCombination,
}: MacroAdjustmentStepThreeProps) => {
  const { t } = useTranslation(["macro", "common"]);
  const { palette } = useTheme();
  const { setFieldValue, values } =
    useFormikContext<MacroAdjustmentWizardFormikValues>();

  const [columnVisibility, setColumnVisibility] = useState({
    baseYear: false,
    targetYear: false,
  });

  useEffect(() => {
    if (
      adjustmentCombination ===
      MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment
    ) {
      setColumnVisibility({ baseYear: true, targetYear: true });
    }
  }, [adjustmentCombination]);

  const {
    data: aggregatedIngredientWeightsData,
    isPending: aggregatedIngredientWeightsDataIsPending,
  } =
    useGetAggregatedIngredientsWeightScenariosMacroAdjustmentsAggregatedIngredientsWeightGet(
      {
        base_year: scenario?.inputs?.years?.base_year ?? 0,
        target_year: scenario?.inputs?.years?.target_year ?? 0,
        country: values.payload.filters.countries[0],
        brand: values?.payload?.filters?.brands?.[0] as number,
        ingredients: values.selectedEntities.map((entity) => entity.id) || [],
      },
      {
        query: {
          enabled:
            adjustmentCombination ===
            MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment,
        },
      },
    );

  const tableData = useMemo(() => {
    return values.selectedEntities.map((entity) => {
      const existingEntity = values.payload.entities.find(
        (e) => e.ingredient_id === entity.id,
      );
      const tableRow: TableEntityType = {
        ingredient_id: entity.id,
        adjusted_value: existingEntity?.adjusted_value ?? null,
        action: existingEntity?.action ?? "percentage",
        baseYear:
          adjustmentCombination ===
          MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment
            ? aggregatedIngredientWeightsData?.base_year.find(
                (item: any) => item.id === entity.id,
              )?.weight
            : undefined,
        targetYear:
          adjustmentCombination ===
          MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment
            ? aggregatedIngredientWeightsData?.target_year.find(
                (item: any) => item.id === entity.id,
              )?.weight
            : undefined,
      };
      return tableRow;
    });
  }, [
    adjustmentCombination,
    aggregatedIngredientWeightsData,
    values.payload.entities,
    values.selectedEntities,
  ]);

  const columns = useMemo<MRT_ColumnDef<TableEntityType>[]>(
    () => [
      {
        accessorKey: "ingredient_id",
        header: t("adjustmentsPage.wizard.stepThree.ingredient"),
        enableEditing: false,
        Cell: ({ row }) => {
          return values.selectedEntities.find(
            (entity) => entity.id === row.original.ingredient_id,
          )?.value;
        },
      },
      {
        accessorKey: "baseYear",
        header: t("adjustmentsPage.wizard.stepThree.baseYear"),
        enableEditing: false,
        Cell: ({ row }) => {
          const baseYear = row.original.baseYear;
          if (baseYear == null) {
            return AppConstant.emptyCell;
          }
          return formatNumber(baseYear, ProductConfigurationsMacro.THREE_DP);
        },
        visible:
          adjustmentCombination ===
          MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment,
      },
      {
        accessorKey: "targetYear",
        header: t("adjustmentsPage.wizard.stepThree.targetYear"),
        enableEditing: false,
        Cell: ({ row }) => {
          const targetYear = row.original.targetYear;
          if (targetYear == null) {
            return AppConstant.emptyCell;
          }
          return formatNumber(targetYear, ProductConfigurationsMacro.THREE_DP);
        },
        visible:
          adjustmentCombination ===
          MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment,
      },
      {
        accessorKey: "adjusted_value",
        Header:
          adjustmentCombination ===
          MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment ? (
            <>
              {values.selectedAction === "percentage"
                ? t(
                    "adjustmentsPage.wizard.stepThree.adjustedValueHeaderPercentage",
                  )
                : t(
                    "adjustmentsPage.wizard.stepThree.adjustedValueHeaderNewValue",
                  )}
              <Box mt={1}>
                <CustomSelect
                  list={["percentage", "new_value"]}
                  label={""}
                  handlerFunction={(value) =>
                    setFieldValue(
                      "selectedAction",
                      value as "percentage" | "new_value",
                    )
                  }
                  source={"macro"}
                  sourceKey={"macro:adjustmentsPage.wizard.stepThree.action"}
                  initialValue={values.selectedAction ?? "new_value"}
                  width={"300px"}
                />
              </Box>
            </>
          ) : (
            t("adjustmentsPage.wizard.stepThree.adjustedValueHeaderPercentage")
          ),
        header: t(
          "adjustmentsPage.wizard.stepThree.adjustedValueHeaderPercentage",
        ),
        // minSize: 150,
        // maxSize: 150,
        enableColumnActions: false,

        muiEditTextFieldProps: ({ row }) => ({
          type: "number",
          required: true,
          placeholder: AppConstant.emptyCell,
          onChange: async (e) => {
            const updatedEntity = { ...row.original };
            const newValue =
              e.target.value === "" ? null : Number(e.target.value);

            setFieldValue(
              "payload.entities",
              values.payload.entities.map((entity) => {
                if (entity.ingredient_id === updatedEntity.ingredient_id) {
                  return {
                    ingredient_id: updatedEntity.ingredient_id,
                    adjusted_value: newValue,
                  };
                } else {
                  return entity;
                }
              }),
            ).catch((e) => console.log(e));
          },
          error: !!validationErrors?.[row.original.ingredient_id],
          helperText: validationErrors?.[row.original.ingredient_id],
          onBlur: (event) => {
            let validationError: string | undefined = undefined;
            const value = Number(event.currentTarget.value);

            if (isNaN(value)) {
              validationError = t("common:validation.mustBeNumber");
            } else if (value === null || value === undefined) {
              validationError = t("common:validation.required");
            } else if (values.selectedAction === "percentage") {
              if (value < -100) {
                validationError = t(
                  "common:validation.greaterThanOrEqualToMinusOneHundred",
                );
              }
              // Validation for "new_value":
            } else if (value < 0) {
              validationError = t("common:validation.greaterThanZero");
            } else {
              validationError = undefined;
            }

            setValidationErrors((prev: Record<number, string | undefined>) => ({
              ...prev,
              [row.original.ingredient_id]: validationError,
            }));
          },
        }),
        muiTableHeadCellProps: () => ({
          sx: {
            color: palette.primary.main,
          },
        }),
      },
    ],
    [
      t,
      adjustmentCombination,
      values.selectedAction,
      values.selectedEntities,
      values.payload.entities,
      setFieldValue,
      validationErrors,
      setValidationErrors,
      palette.primary.main,
    ],
  );

  const table = useMaterialReactTable({
    columns,
    data: tableData,
    editDisplayMode: "table",
    enableEditing: true,
    enableColumnActions: false,
    enableSorting: false,
    enablePagination: true,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 100,
      },
    },
    state: { columnVisibility },
    onColumnVisibilityChange:
      setColumnVisibility as OnChangeFn<VisibilityState>,
    getRowId: (row: any) => row.id,
    muiTableContainerProps: {
      sx: {
        border: "1px solid #E0E0E0",
        overflowY: "auto",
      },
    },
  });

  return (
    <Box>
      <Box mb={1}>
        <Typography className="header-H2 text-ellipsis">
          {t("adjustmentsPage.wizard.stepThree.title")}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          {t("adjustmentsPage.wizard.stepThree.description")}
        </Typography>
      </Box>
      {adjustmentCombination ===
        MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment &&
      aggregatedIngredientWeightsDataIsPending ? (
        <Loader />
      ) : (
        <MaterialTable table={table} />
      )}
    </Box>
  );
};
