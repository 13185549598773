import { Chart } from "chart.js";
import { Options } from "chartjs-plugin-datalabels/types/options";

export const LegendMarginPlugin = {
  id: "legendMargin",
  afterInit(chart: Chart, args: Object, pluginOptions: Options) {
    if (!chart.legend) {
      return;
    }
    const originalFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      if (originalFit) {
        originalFit.call(this);
      }
      return (this.height += 25);
    };
  },
};
