import { Typography, Box, Button, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { IconButton } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  UpdateMacroScenarioViewModel,
  UpdateScenarioDetails,
  ViewMacroScenarioViewModel,
  ViewMicroScenario,
} from "orval/generated/models";
import { useSnackbar } from "../Notification/showSnackbar";
import * as yup from "yup";
import { useFormik } from "formik";
import { useModal } from "components/common/Modal";

export interface UpdateDetailsModalModalProps {
  open: boolean;
  scenario: ViewMicroScenario | ViewMacroScenarioViewModel;
  updateScenarioFunction: Function;
}

const UpdateDetailsModal = ({
  open,
  scenario,
  updateScenarioFunction,
}: UpdateDetailsModalModalProps) => {
  const { t } = useTranslation(["micro", "common"]);
  const updateNameInputRef = useRef<HTMLInputElement>(null);
  const showSnackbar = useSnackbar();
  const [microOrMacro, setMicroOrMacro] = useState<string | null>(null);
  const { closeModal } = useModal();

  useEffect(() => {
    if ("type" in scenario && scenario.type === "macro") {
      setMicroOrMacro("macro");
    } else {
      setMicroOrMacro("micro");
    }
  }, [scenario]);

  const scenarioUpdateDetailsModalSchema = yup.object().shape({
    name: yup
      .string()
      .max(60, "Name must be at most 60 characters")
      .min(3, "Name must be at least 3 characters")
      .required("Name is required"),
    description: yup
      .string()
      .max(255, "Description must be at most 255 characters"),
  });

  useEffect(() => {
    if (open && updateNameInputRef.current) {
      updateNameInputRef.current.focus();
    }
  }, [open]);

  const handleUpdateMacroScenarioDetails = useCallback(
    async (
      _scenario: ViewMacroScenarioViewModel,
      name: string,
      description: string,
    ) => {
      if (_scenario != null && _scenario.id != null) {
        const _updateScenarioPayload: UpdateMacroScenarioViewModel = {
          name: name,
          description: description,
          inputs: _scenario.inputs,
        };
        const isUpdated: boolean = await updateScenarioFunction(
          _scenario.id,
          _updateScenarioPayload,
        );

        if (!isUpdated) {
          showSnackbar(
            t(
              "micro:microViewAllScenariosPage.updateDetailsModal.duplicateError",
            ),
            "error",
          );
        }
      }
    },
    [showSnackbar, t, updateScenarioFunction],
  );

  const formik = useFormik<UpdateScenarioDetails>({
    initialValues: {
      description: scenario?.description ?? "",
      name: scenario?.name ?? "",
    },
    validationSchema: scenarioUpdateDetailsModalSchema,
    onSubmit: (values) => {
      if (microOrMacro === "macro") {
        values.name &&
          handleUpdateMacroScenarioDetails(
            scenario as unknown as ViewMacroScenarioViewModel,
            values.name,
            values.description as string,
          );
      } else if (microOrMacro === "micro") {
        values.name &&
          updateScenarioFunction({
            scenarioId: scenario.id,
            data: {
              description: values.description,
              name: values.name,
            },
          });
      }
      closeModal();
    },
  });

  return (
    <Box minWidth={600} minHeight={485}>
      <Box>
        <Box
          marginRight={1}
          marginTop={1}
          display="flex"
          justifyContent="right"
        >
          <IconButton
            aria-label="delete"
            sx={{ aspectRatio: 1, float: "right" }}
            onClick={closeModal}
            autoFocus={true}
          >
            <CloseSharpIcon />
          </IconButton>
        </Box>
        <Box paddingX={5} paddingBottom={4}>
          <Typography textAlign="center" variant="h4" marginBottom={3}>
            {t("micro:microViewAllScenariosPage.updateDetailsModal.title")}
          </Typography>
          <Typography textAlign="center" variant="body2" marginBottom={3}>
            {t(
              "micro:microViewAllScenariosPage.updateDetailsModal.description",
            )}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                id="name"
                label={t(
                  "micro:microViewAllScenariosPage.updateDetailsModal.labels.name",
                )}
                variant="outlined"
                required
                sx={{ marginBottom: 2 }}
                inputRef={updateNameInputRef}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                data-testid={"update-name-input"}
              />
              <TextField
                id="description"
                label={t(
                  "micro:microViewAllScenariosPage.updateDetailsModal.labels.description",
                )}
                multiline
                rows={4}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                data-testid={"update-description-input"}
              />
            </Box>
            <Box display="flex" justifyContent="center" sx={{ marginTop: 4 }}>
              <Button
                sx={{ mr: 2 }}
                variant="outlined"
                onClick={closeModal}
                data-testid={"cancel-update-scenario-button"}
              >
                {t("common:actions.cancel")}
              </Button>
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                type="submit"
                disabled={!formik.dirty || !formik.isValid}
                data-testid={"update-scenario-button"}
              >
                {t("common:actions.update")}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateDetailsModal;
