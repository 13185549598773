import { Button, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";

export interface NoDataProps {
  label?: string;
  label2?: string;
  actionButtonLabel?: string;
  onActionButtonClick?: Function;
}

export const NoData = ({
  label,
  label2,
  actionButtonLabel,
  onActionButtonClick,
}: NoDataProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        height: "171px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        border: "thin dotted",
      }}
      data-testid="no-data-box"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        {label ? (
          <Box pb={3}>
            <Typography variant="h4">{label}</Typography>
          </Box>
        ) : (
          <Box></Box>
        )}
        {label2 ? (
          <Box>
            <Typography variant="h5" fontSize="1rem">
              {label2}
            </Typography>
          </Box>
        ) : (
          <Box></Box>
        )}
        {!label && !label2 && (
          <>
            <Box>
              <Typography className="header-H3">
                {t("common:labels.noDataPresent1")}
              </Typography>
            </Box>
            <Box>
              <Typography className="header-H3">
                {t("common:labels.noDataPresent2")}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      {actionButtonLabel && (
        <Button
          sx={{ mt: 3 }}
          variant="contained"
          onClick={() => {
            if (onActionButtonClick) {
              onActionButtonClick();
            }
          }}
        >
          {t(actionButtonLabel)}
        </Button>
      )}
    </Box>
  );
};
