import { Box, Typography, useTheme } from "@mui/material";
import { ViewMacroScenarioViewModel } from "orval/generated/models";
import {
  CombinedDataItem,
  TableResultsFilterDataAccessorKey,
  TableResultsFilterDataType,
  TableResultsType,
} from "../ScenarioResults/types";
import {
  MaterialReactTable,
  MRT_Column,
  MRT_Row,
  useMaterialReactTable,
} from "material-react-table";
import { CustomSelect } from "components/common/CustomSelect";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import "./MacroPillarDeepDiveTable.scss";
import getResultsOverviewTableData from "./HelperFunctions/getResultsOverviewTableData";
import getPillarDeepDiveTableColumns from "./HelperFunctions/getPillarDeepDiveTableColumns";
import pillarDeepDiveAccessorKeys from "../ScenarioResults/pillarDeepDiveAccessorKeys";

export type PillarDeepDiveTableProps = {
  title: string;
  initialFilter?: TableResultsFilterDataType;
  scenario: ViewMacroScenarioViewModel | null;
  tableType: TableResultsType;
};

const MacroPillarDeepDiveTable = ({
  title,
  initialFilter,
  scenario,
  tableType,
}: PillarDeepDiveTableProps) => {
  const { palette } = useTheme();
  const { t } = useTranslation("macro");
  const [filterType, setFilterType] = useState<TableResultsFilterDataType>(
    initialFilter ? initialFilter : TableResultsFilterDataType.ABSOLUTE,
  );
  const [subtitle, setSubtitle] = useState<string>(
    t("resultsSection.pillarDeepDiveTable.subtitles.absoluteEmissions"),
  );

  const computedData: CombinedDataItem[] = useMemo(() => {
    return scenario
      ? getResultsOverviewTableData(
          tableType,
          scenario,
          filterType === TableResultsFilterDataType.ABSOLUTE
            ? TableResultsFilterDataAccessorKey.ABSOLUTE
            : TableResultsFilterDataAccessorKey.PER_LITRE,
        )
      : [];
  }, [scenario, tableType, filterType]);

  const columns: {
    accessorKey: pillarDeepDiveAccessorKeys | string;
    header: string;
    id: pillarDeepDiveAccessorKeys | string;
  }[] = getPillarDeepDiveTableColumns();

  const table = useMaterialReactTable<CombinedDataItem>({
    columns: columns.map((column) => ({
      ...column,
      Cell: ({
        column: cellColumn,
        row,
        renderedCellValue,
      }: {
        column: MRT_Column<CombinedDataItem>;
        row: MRT_Row<CombinedDataItem>;
        renderedCellValue: any;
      }) => {
        const rowToRemove = (row.index + 2) % 3 !== 0;
        const isFirstColumn = cellColumn.id === columns[0].id;
        const isSecondColumn = cellColumn.id === columns[1].id;
        if (isSecondColumn) {
          const cellValue =
            row?.original?.base ||
            row?.original?.glide ||
            row?.original?.scenario;
          if (cellValue === scenario?.inputs?.years?.base_year) {
            return `${t("resultsSection.pillarDeepDiveTable.baseYear")} (${cellValue})`;
          } else if (cellValue === scenario?.inputs?.years?.target_year) {
            return `${t("resultsSection.pillarDeepDiveTable.scenario")} (${cellValue})`;
          } else
            return `${t("resultsSection.pillarDeepDiveTable.glidePath")} (${scenario?.inputs?.years?.target_year})`;
        } else if (rowToRemove && isFirstColumn) {
          return;
        } else {
          return renderedCellValue;
        }
      },
    })),
    data: computedData,
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnPinning: true,
    initialState: {
      columnPinning: {
        left: ["category", "baseGlideScenario"],
      },
    },
    muiTablePaperProps: {
      sx: {
        elevation: 0,
        boxShadow: "none",
      },
    },
    defaultColumn: {
      muiTableBodyCellProps: ({ row, column }) => ({
        className:
          column.id === columns[0].id
            ? "first-column"
            : column.id === columns[1].id
              ? "second-column"
              : "other-column",
        sx: {
          color:
            (row.index + 3) % 3 === 2 ? `${palette.primary.main}` : "black",
          fontWeight: column.id === columns[0].id ? "bold" : "initial",
          borderBottom:
            (row.index + 1) % 3 === 0
              ? `medium solid ${palette.textColor.light} !important`
              : column.id === columns[0].id
                ? "thin solid white !important"
                : `thin solid ${palette.textColor.light} !important`,
          textAlign: "center",
        },
      }),
      muiTableHeadCellProps: ({ column }) => ({
        className:
          column.id === columns[0].id
            ? "first-head-cell"
            : column.id === columns[1].id
              ? "second-head-cell"
              : "other-head-cell",
        sx: {
          textAlign: "center",
          borderBottom: `medium solid ${palette.textColor.light} !important`,
        },
      }),
    },
  });

  useEffect(() => {
    if (filterType === TableResultsFilterDataType.ABSOLUTE) {
      setSubtitle(
        t(
          "macro:resultsSection.pillarDeepDiveTable.subtitles.absoluteEmissions",
        ),
      );
    } else {
      setSubtitle(`
        ${t("macro:resultsSection.pillarDeepDiveTable.subtitles.emissionsPerLitre")}
      `);
    }
  }, [filterType, t]);

  return (
    <Box data-testid="pillar-deep-dive-table-box" className="displayHeader">
      <Box
        sx={{
          textAlign: "start",
          display: "flex",
          margin: "1rem 0",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography className="tableHeader">{title}</Typography>
          <Typography className="tableSubHeader">{subtitle}</Typography>
        </Box>

        <Box width={"200px"} ml={2} data-testid="pillar-deep-dive-filter-box">
          <CustomSelect
            list={Object.values(TableResultsFilterDataType)}
            label={t("macro:resultsSection.pillarDeepDiveTable.filterBy")}
            handlerFunction={(value) =>
              setFilterType(value as TableResultsFilterDataType)
            }
            source={"macro"}
            sourceKey={
              "macro:resultsSection.carbonEmissionOverview.filters.units"
            }
            initialValue={TableResultsFilterDataType.ABSOLUTE}
          />
        </Box>
      </Box>
      {computedData ? <MaterialReactTable table={table} /> : null}
    </Box>
  );
};

export default MacroPillarDeepDiveTable;
