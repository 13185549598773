import { AxiosError } from "axios";
import { useSnackbar } from "components/common/Notification/showSnackbar";
import { AXIOS_INSTANCE } from "orval/mutator/custom-instance";
import { createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SupportingPagesRoutesConfig } from "features/supporting-pages";
import { revokeTokenAndEndSession } from "features/auth/utils/verifyPwCAccess";
import { STATUS_CODES } from "utils/HTTPUtils/constants";
import useInitiateLogout from "../../features/auth/utils/useInitiateLogout";

const AxiosContext = createContext(AXIOS_INSTANCE);

export function AxiosProvider({ instance = AXIOS_INSTANCE, ...props }) {
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const initiateLogout = useInitiateLogout();

  useEffect(() => {
    const authInterceptor = instance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (error.response) {
          const { status } = error.response;
          console.warn(error);
          switch (status) {
            case STATUS_CODES.UNAUTHORIZED:
              await revokeTokenAndEndSession(initiateLogout);
              break;

            case STATUS_CODES.UNAVAILABLE:
              showSnackbar(error, "info");
              break;

            case STATUS_CODES.FORBIDDEN:
              if (
                window.location.pathname !==
                SupportingPagesRoutesConfig.accessDenied
              )
                navigate(SupportingPagesRoutesConfig.accessDenied);
              break;
            default:
              break;
          }
        }
        return Promise.reject(error);
      },
    );

    return () => {
      instance.interceptors.response.eject(authInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AxiosContext.Provider value={instance} {...props} />;
}
