import Box from "@mui/material/Box";
import { Autocomplete, Button, TextField, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid2";
import { useTranslation } from "react-i18next";
import {
  MacroAdjustmentCombination,
  MacroAdjustmentCombinations,
  MacroAdjustmentWizardFormikValues,
} from "../MacroAdjustmentTypes";
import SelectDropdown from "components/common/SelectDropdown/SelectDropdown";
import SalesVolumeChartArea from "./SalesVolumeChartArea/SalesVolumeChartArea";
import {
  MacroFilter,
  ViewMacroScenarioViewModel,
  MacroFilterValue,
  MacroFilters,
  CountryViewModel,
  MacroFiltersAndFilteredRTDL,
  IngredientMacroFilters,
} from "orval/generated/models";
import { useCallback, useEffect } from "react";
import { useFormikContext } from "formik";
import { useGetTotalRtdlScenarioMacroTotalRtdlGet } from "orval/generated/endpoint";
import StepTwoFilterDropdowns from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentStepTwo/StepTwoFilterDropdowns";

export type MacroAdjustmentStepTwoProps = {
  adjustmentCombination: MacroAdjustmentCombination | null;
  scenario: ViewMacroScenarioViewModel | undefined;
  filtersData: MacroFiltersAndFilteredRTDL | undefined;
  setMacroFiltersParams: Function;
  filtersDataIsPending: boolean;
};

export const MacroAdjustmentStepTwo = ({
  adjustmentCombination,
  scenario,
  filtersData,
  setMacroFiltersParams,
  filtersDataIsPending,
}: MacroAdjustmentStepTwoProps) => {
  const { t } = useTranslation("macro");
  const { values, setFieldValue } =
    useFormikContext<MacroAdjustmentWizardFormikValues>();

  const { data: totalRTDL } = useGetTotalRtdlScenarioMacroTotalRtdlGet({
    countries:
      scenario?.inputs?.countries?.map((country) => country.country) || [],
    target_year: scenario?.inputs?.years?.target_year || 2030,
  });

  const handleCountriesSave = useCallback(
    (countries: CountryViewModel[]) => {
      let selectedCountryCodes: string[] = [];
      if (!countries.length) {
        selectedCountryCodes =
          scenario?.inputs?.countries?.map((country) => country.country) || [];
      } else {
        selectedCountryCodes = countries?.map((country) => country.country);
      }
      setFieldValue("payload.filters", {
        ...values.payload.filters,
        countries: selectedCountryCodes,
      }).catch((error) => {
        console.warn(error);
      });

      setMacroFiltersParams((prev: any) => ({
        ...prev,
        countries: selectedCountryCodes,
      }));
    },
    [
      scenario?.inputs?.countries,
      setFieldValue,
      setMacroFiltersParams,
      values.payload.filters,
    ],
  );

  const handleEntitiesSave = useCallback(
    (selectedItems: MacroFilterValue[], entityType: keyof MacroFilters) => {
      const items = filtersData?.filters[entityType].filter(
        (item: MacroFilter) => selectedItems.includes(item.value),
      );
      if (items?.length) {
        setFieldValue("selectedEntities", items).catch((error) => {
          console.warn(error);
        });
      } else {
        setFieldValue("selectedEntities", []).catch((error) => {
          console.warn(error);
        });
      }
    },
    [filtersData?.filters, setFieldValue],
  );

  const handleResetPageTwo = useCallback(() => {
    setFieldValue("payload.filters", {
      categories: [],
      brand_groups: [],
      brands: [],
      ingredients: [],
    }).catch((error) => {
      console.warn(error);
    });

    setFieldValue("selectedCountries", []).catch((error) => {
      console.warn(error);
    });
    setFieldValue("selectedEntities", []).catch((error) => {
      console.warn(error);
    });
    setMacroFiltersParams({
      countries:
        scenario?.inputs?.countries?.map((country) => country.country) || [],
      target_year: scenario?.inputs?.years?.target_year || 2045,
    });
  }, [
    scenario?.inputs?.countries,
    scenario?.inputs?.years?.target_year,
    setFieldValue,
    setMacroFiltersParams,
  ]);

  const renderCountriesDropdown = useCallback(() => {
    if (!adjustmentCombination) {
      return null;
    }

    switch (adjustmentCombination.combo) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
        return (
          <SelectDropdown
            listItems={
              scenario?.inputs?.countries?.map(
                (country) => country.display_name,
              ) || []
            }
            savedSelectedItems={values.selectedCountries?.map(
              (country) => country.display_name,
            )}
            onSave={(selectedItems) => {
              const selectedCountriesAsCountryViewModels = selectedItems?.map(
                (countryDisplayName) => {
                  return scenario?.inputs?.countries?.find(
                    (country) => country.display_name === countryDisplayName,
                  );
                },
              );

              setFieldValue(
                "selectedCountries",
                selectedCountriesAsCountryViewModels,
              ).catch((error) => {
                console.warn(error);
              });
              handleCountriesSave(
                selectedCountriesAsCountryViewModels as CountryViewModel[],
              );
            }}
            title={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.countries")}*`}
            compact
            selectAll={true}
            disabled={scenario?.inputs?.countries?.length === 0}
          />
        );
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        return (
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.country")}*`}
                name={"country"}
              />
            )}
            value={values.selectedCountries[0]?.display_name || null}
            options={
              scenario?.inputs?.countries?.map(
                (country) => country.display_name,
              ) || []
            }
            size={"small"}
            onChange={(_event, value) => {
              const selectedItemAsCountryViewModel =
                scenario?.inputs?.countries?.find(
                  (country) => country.display_name === value,
                );

              if (selectedItemAsCountryViewModel) {
                setFieldValue("selectedCountries", [
                  selectedItemAsCountryViewModel,
                ]).catch((error) => {
                  console.warn(error);
                });
                handleCountriesSave([
                  selectedItemAsCountryViewModel,
                ] as CountryViewModel[]);
              }
            }}
            clearOnBlur
            handleHomeEndKeys
          />
        );
      default:
        return null;
    }
  }, [
    adjustmentCombination,
    handleCountriesSave,
    scenario?.inputs?.countries,
    setFieldValue,
    t,
    values.selectedCountries,
  ]);

  const renderEntitiesDropdowns = useCallback(() => {
    if (!adjustmentCombination) {
      return null;
    }
    switch (adjustmentCombination.combo) {
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment:
      case MacroAdjustmentCombinations.Ingredients_IngredientWeights_SpecificBrandAdjustment:
        return (
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid size={4}>
                <SelectDropdown
                  listItems={
                    filtersData?.filters.ingredients?.map(
                      (ingredient: MacroFilter) => ingredient.value,
                    ) || []
                  }
                  savedSelectedItems={values?.selectedEntities?.map(
                    (entity) => entity?.value,
                  )}
                  onSave={(selectedItems) => {
                    handleEntitiesSave(selectedItems, "ingredients");
                  }}
                  title={`${t("adjustmentsPage.wizard.stepTwo.dropdownTitles.ingredients")}*`}
                  compact
                  selectAll={true}
                  disabled={values.selectedCountries.length === 0}
                />
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return null;
    }
  }, [
    adjustmentCombination,
    filtersData?.filters.ingredients,
    handleEntitiesSave,
    t,
    values.selectedCountries.length,
    values?.selectedEntities,
  ]);

  useEffect(() => {
    if (filtersData) {
      Object.keys(values.payload.filters).forEach((filter) => {
        if (
          filtersData.filters[filter as keyof MacroFilters]?.length <
          (values.payload.filters[filter as keyof IngredientMacroFilters]
            ?.length as number)
        ) {
          setFieldValue(
            `payload.filters.${filter}`,
            filtersData.filters[filter as keyof MacroFilters]?.map(
              (_filter) => _filter.id,
            ),
          ).catch((error) => {
            console.warn(error);
          });

          setMacroFiltersParams((prev: any) => ({
            ...prev,
            [filter]: filtersData.filters[filter as keyof MacroFilters]?.map(
              (_filter) => _filter.id,
            ),
          }));
        }
      });
    }
  }, [
    filtersData,
    setFieldValue,
    setMacroFiltersParams,
    values.payload.filters,
  ]);

  useEffect(() => {
    if (values.selectedEntities.length !== values.payload.entities.length) {
      setFieldValue(
        "payload.entities",
        values.selectedEntities.map((entity) => {
          const existing = values.payload.entities.find(
            (e) => e.ingredient_id === entity.id,
          );
          if (existing) {
            return existing;
          } else {
            return {
              ingredient_id: entity.id,
              adjusted_value: null,
              action: "percentage",
            };
          }
        }),
      ).catch((error) => {
        console.warn(error);
      });
    }
  }, [setFieldValue, values.payload.entities, values.selectedEntities]);

  return (
    <Box>
      <Box
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        mb={2}
      >
        <Box>
          <Typography className="header-H2 text-ellipsis">
            {t("adjustmentsPage.wizard.stepTwo.title")}
          </Typography>
        </Box>
        <Box
          sx={{ minWidth: "200px" }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <Button
            variant="outlined"
            color={"primary"}
            startIcon={<ClearIcon />}
            onClick={handleResetPageTwo}
          >
            {t("adjustmentsPage.wizard.stepTwo.resetFilters")}
          </Button>
        </Box>
      </Box>

      <Box mt={2} mb={1}>
        <Typography className="header-H3 text-ellipsis">
          {t("adjustmentsPage.wizard.stepTwo.selectCountries")}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          {t("adjustmentsPage.wizard.stepTwo.selectCountriesDescription")}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid size={4}>{renderCountriesDropdown()}</Grid>
      </Grid>

      <Box mt={2} mb={1}>
        <Typography className="header-H3 text-ellipsis">
          {t("adjustmentsPage.wizard.stepTwo.optionalSelectFilters")}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          {t("adjustmentsPage.wizard.stepTwo.optionalSelectFiltersDescription")}
        </Typography>
      </Box>

      <Box mt={3}>
        <Grid container spacing={2}>
          <StepTwoFilterDropdowns
            adjustmentCombination={adjustmentCombination?.combo}
            filtersData={filtersData}
            setMacroFiltersParams={setMacroFiltersParams}
          ></StepTwoFilterDropdowns>
        </Grid>
      </Box>

      <SalesVolumeChartArea
        totalRTDL={totalRTDL || 0}
        filteredRTDL={filtersData?.filtered_rtdl || 0}
        targetYear={scenario?.inputs?.years?.target_year || 2030}
        filtersDataIsPending={filtersDataIsPending}
      />

      <Box mt={4} mb={1}>
        <Typography className="header-H3 text-ellipsis">
          {t("adjustmentsPage.wizard.stepTwo.selectIngredients")}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography>
          {t("adjustmentsPage.wizard.stepTwo.selectIngredientsDescription")}
        </Typography>
      </Box>

      {renderEntitiesDropdowns()}
    </Box>
  );
};
