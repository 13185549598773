import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useModal } from "components/common/Modal";
import ReportIssueModalContent from "./ReportIssueModalContent";
import { CustomIcon, Icons } from "components/common";

export default function ReportIssue() {
  const { t } = useTranslation("common");
  const { openModal } = useModal();

  return (
    <Tooltip title={t("reportIssue.toolTip")}>
      <IconButton
        onClick={() => {
          openModal(<ReportIssueModalContent />, true);
        }}
        size="small"
        sx={{ ml: 2 }}
        data-testid="report-icon-btn"
      >
        <CustomIcon
          name={Icons.HELP_OUTLINE}
          width={"32px"}
          height={"28px"}
          fill={"#d04a02"}
        />
      </IconButton>
    </Tooltip>
  );
}
