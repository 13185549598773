import { Box, Typography } from "@mui/material";
import EmissionTimeChart from "./EmissionTimeChart/EmissionTimeChart";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { timeChartData, timeEvolutionTableData } from "../../types";
import { MacroOptionalMetricsTable } from "../MacroOptionalMetricsTable/MacroOptionalMetricsTable";
import { ViewMacroScenarioViewModel } from "orval/generated/models";
import {
  TableResultsType,
  TableResultsFilterDataType,
} from "../ScenarioResults/types";
import getTimeEvolutionData from "./HelperFunctions/getTimeEvolutionData";
import getTimeEvolutionLabels from "./HelperFunctions/getTimeEvolutionLabels";
import getTableData from "./HelperFunctions/getTableData";
import getTitle from "./HelperFunctions/getTitle";
import getUnitsToDisplay from "../HelperFunctions/getUnitsToDisplay";
import { CustomSelect } from "components/common/CustomSelect";

export type EmissionTimeSectionProps = {
  scenario: ViewMacroScenarioViewModel | null;
  sectionType: TableResultsType.BY_CATEGORY | TableResultsType.BY_PACK_TYPE;
};

function EmissionTimeSection({
  scenario,
  sectionType,
}: EmissionTimeSectionProps) {
  const { t } = useTranslation("macro");
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [tableData, setTableData] = useState<timeEvolutionTableData | []>([]);
  const [chartData, setChartData] = useState<timeChartData | []>([]);
  const [units, setUnits] = useState<TableResultsFilterDataType>(
    TableResultsFilterDataType.ABSOLUTE,
  );
  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");

  const translations = useMemo(
    () => ({
      base: t("timeEvolutionLegend.base"),
      glide: t("timeEvolutionLegend.glide"),
      scenario: t("timeEvolutionLegend.scenario"),
    }),
    [t],
  );

  useEffect(() => {
    if (scenario?.results && sectionType) {
      setChartData(getTimeEvolutionData(scenario.results, units, sectionType));
      setChartLabels(getTimeEvolutionLabels(scenario?.results?.[sectionType]));
      setTableData(getTableData(scenario?.results, units, sectionType));

      const title = getTitle(sectionType, units);
      setTitle(t(`${title}`));

      const unitsToDisplay = getUnitsToDisplay(units);
      setSubtitle(t(`${unitsToDisplay}`));
    }
  }, [sectionType, scenario, translations, units, t]);

  const columns = [
    {
      accessorKey: "category", //simple recommended way to define a column
      header:
        sectionType === TableResultsType.BY_CATEGORY
          ? t("resultsSection.by_category")
          : t("resultsSection.by_pack_type"),
      id: "category",
    },
    {
      accessorKey: "base_to_glide_change", //simple recommended way to define a column
      header: t("resultsSection.timeEvolutionTable.baseGlidepath"),
      id: "base_to_glide_change",
    },
    {
      accessorKey: "glide_to_scenario_change",
      header: t("resultsSection.timeEvolutionTable.glidepathScenario"),
      id: "glide_to_scenario_change",
    },
  ];

  return (
    <Box my={2} display={"flex"} flexDirection={"column"}>
      <Box alignSelf={"flex-end"}>
        <Box width={"200px"} ml={2}>
          <CustomSelect
            list={Object.values(TableResultsFilterDataType)}
            label={t("macro:resultsSection.filterBy")}
            handlerFunction={(value) =>
              setUnits(value as TableResultsFilterDataType)
            }
            source={"macro"}
            sourceKey="dataVisualisationTabSection.timeEvolution.filters"
            initialValue={TableResultsFilterDataType.ABSOLUTE}
          />
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: "start",
          display: "flex",
          flexDirection: "column",
        }}
        mb={2}
      >
        <Typography className="tableHeader">{title}</Typography>
        <Typography className="tableSubHeader">{subtitle}</Typography>
      </Box>
      <EmissionTimeChart
        labels={chartLabels}
        timeChartData={chartData}
        units={units}
      />
      <MacroOptionalMetricsTable
        columns={columns}
        data={tableData}
        displaySubtitle={false}
        title={t("resultsSection.timeEvolutionTable.title")}
      />
    </Box>
  );
}

export default EmissionTimeSection;
