import Box from "@mui/material/Box";
import { AccordionComponent } from "../../AccordionComponent";
import { useTranslation } from "react-i18next";
import { ViewMacroScenarioViewModel } from "orval/generated/models";
import { SUB_ACCORDION_INDENT } from "../../../constants";
import MacroPillarDeepDiveTable from "../../MacroPillarDeepDiveTable/MacroPillarDeepDiveTable";
import { TableResultsFilterDataType, TableResultsType } from "../types";
import { CarbonEmissionsBySection } from "../../CarbonEmissionsBySection/CarbonEmissionsBySection";
import { OptionalMetricsTableResultsType } from "../../MacroOptionalMetricsTable/Types";

export type ResultsTabSectionProps = {
  disabled: boolean;
  scenario: ViewMacroScenarioViewModel | null;
};

export function ResultsTabSection({
  disabled,
  scenario,
}: ResultsTabSectionProps) {
  const { t } = useTranslation("macro");

  const subAccordionLabelsOverview = [
    {
      label: "resultsTabSection.subAccordionLabels.byCategory",
      component: (
        <CarbonEmissionsBySection
          scenario={scenario}
          type={OptionalMetricsTableResultsType.BY_CATEGORY}
        />
      ),
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPack",
      component: (
        <CarbonEmissionsBySection
          scenario={scenario}
          type={OptionalMetricsTableResultsType.BY_PACK_TYPE}
        />
      ),
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPillar",
      component: (
        <CarbonEmissionsBySection
          scenario={scenario}
          type={OptionalMetricsTableResultsType.BY_PILLAR}
        />
      ),
    },
  ];

  const subAccordionLabelsPillarDeepDive = [
    {
      label: "resultsTabSection.subAccordionLabels.byCategory",
      component: (
        <MacroPillarDeepDiveTable
          title={t("resultsSection.pillarDeepDiveTable.titles.byCategory")}
          initialFilter={TableResultsFilterDataType.ABSOLUTE}
          scenario={scenario}
          tableType={TableResultsType.BY_CATEGORY}
        />
      ),
    },
    {
      label: "resultsTabSection.subAccordionLabels.byPack",
      component: (
        <MacroPillarDeepDiveTable
          title={t("resultsSection.pillarDeepDiveTable.titles.byPack")}
          initialFilter={TableResultsFilterDataType.ABSOLUTE}
          scenario={scenario}
          tableType={TableResultsType.BY_PACK_TYPE}
        />
      ),
    },
  ];

  return (
    <Box mb={10}>
      <AccordionComponent
        title={t("resultsTabSection.accordionLabels.overview")}
        disabled={disabled}
      >
        {subAccordionLabelsOverview.map((item) => (
          <Box key={item.label} ml={SUB_ACCORDION_INDENT}>
            <AccordionComponent title={t(item.label)} disabled={disabled}>
              {item.component}
            </AccordionComponent>
          </Box>
        ))}
      </AccordionComponent>
      <AccordionComponent
        title={t("resultsTabSection.accordionLabels.pillarDeepDive")}
        disabled={disabled}
      >
        {subAccordionLabelsPillarDeepDive.map((item) => (
          <Box key={item.label} ml={SUB_ACCORDION_INDENT}>
            <AccordionComponent title={t(item.label)} disabled={disabled}>
              {item.component}
            </AccordionComponent>
          </Box>
        ))}
      </AccordionComponent>
    </Box>
  );
}
