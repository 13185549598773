import { Box, Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormikProps } from "formik";
import {
  MacroAdjustmentCombinations,
  MacroAdjustmentStep,
  MacroAdjustmentWizardFormikValues,
} from "features/macro-tool/components/MacroAdjustmentWizard/MacroAdjustmentTypes";

export type MacroAdjustmentsWizardFooterProps = {
  handleLeftButtonClick: () => void;
  steps: (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => MacroAdjustmentStep[];
  formikProps: FormikProps<MacroAdjustmentWizardFormikValues>;
  activeStep: number;
  handleRightButtonClick: (
    formikProps: FormikProps<MacroAdjustmentWizardFormikValues>,
  ) => void;
  adjustmentCombination: MacroAdjustmentCombinations | null;
  validationErrors: Record<number, string | undefined>;
};

const MacroAdjustmentsWizardFooter = ({
  handleLeftButtonClick,
  steps,
  formikProps,
  activeStep,
  handleRightButtonClick,
  adjustmentCombination,
  validationErrors,
}: MacroAdjustmentsWizardFooterProps) => {
  const [rightButtonDisabled, setRightButtonDisabled] = useState(true);
  const { values } = formikProps;

  const updateRightButtonDisabled = useCallback(() => {
    switch (activeStep) {
      case 0:
        setRightButtonDisabled(
          adjustmentCombination === null ||
            values.payload.name === "" ||
            values.payload.name === null,
        );
        break;
      case 1:
        setRightButtonDisabled(
          adjustmentCombination ===
            MacroAdjustmentCombinations.Ingredients_IngredientWeights_MultiBrandAdjustment
            ? values.selectedEntities.length === 0
            : values.selectedEntities.length === 0 ||
                values.payload.filters.brands?.length === 0,
        );
        break;
      case 2:
        setRightButtonDisabled(
          values.payload.entities.some(
            (entity) => entity.adjusted_value === null,
          ) ||
            Object.values(validationErrors).some(
              (error) => error !== undefined,
            ),
        );
        break;
      default:
        setRightButtonDisabled(false);
    }
  }, [
    activeStep,
    adjustmentCombination,
    validationErrors,
    values.payload.entities,
    values.payload.filters.brands,
    values.payload.name,
    values.selectedEntities,
  ]);

  useEffect(() => {
    updateRightButtonDisabled();
  }, [activeStep, formikProps, updateRightButtonDisabled]);

  return (
    <Box display={"flex"} justifyContent={"space-between"} mb={3}>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => handleLeftButtonClick()}
      >
        {steps(formikProps)[activeStep].leftBtnText}
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleRightButtonClick(formikProps)}
        disabled={rightButtonDisabled}
      >
        {steps(formikProps)[activeStep].rightBtnText}
      </Button>
    </Box>
  );
};

export default MacroAdjustmentsWizardFooter;
