import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { Email, Person } from "@mui/icons-material";
import { getDecodedSessionInfo } from "utils/HTTPUtils/session-service";
import { TokenTypes } from "utils/HTTPUtils/enums";
import { IdamUser, useAuthUser } from "core";
import { revokeTokenAndEndSession } from "features/auth/utils/verifyPwCAccess";
import useInitiateLogout from "features/auth/utils/useInitiateLogout";
import { useSnackbar } from "../../common/Notification/showSnackbar";
import { useGlobalLoader } from "../../common";
import { useState, MouseEvent } from "react";

export default function AccountMenu() {
  const { t } = useTranslation("common");
  const sessionIDInfo: any = getDecodedSessionInfo(TokenTypes.ID_TOKEN);
  const userInfo: IdamUser | undefined = useAuthUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const initiateLogout = useInitiateLogout();
  const showSnackbar = useSnackbar();
  const { showGlobalLoader } = useGlobalLoader();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    try {
      showGlobalLoader(true);
      await revokeTokenAndEndSession(initiateLogout);
    } catch (error) {
      console.warn("Error logging out", error);
      showSnackbar(t("messages.errorLoggingOut"), "error");
    } finally {
      showGlobalLoader(false);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("accountMenu.accountSettings")}>
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            data-testid="account-menu-icon-button"
          >
            <Avatar
              alt={sessionIDInfo?.name || userInfo?.name}
              src={sessionIDInfo?.picture}
              sx={{ bgcolor: "#d04a02", fontSize: 12, width: 32, height: 32 }}
            >
              {`${userInfo?.given_name?.charAt(0)}${userInfo?.family_name?.charAt(0)}`}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
              mr: 0.4,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        data-testid="account-menu"
      >
        <MenuItem
          sx={{ cursor: "default" }}
          onClick={handleClose}
          data-testid="person-menu-item"
        >
          <ListItemIcon>
            <Person fontSize="small" />
          </ListItemIcon>
          {sessionIDInfo?.name || userInfo?.name}
        </MenuItem>
        <MenuItem
          sx={{ cursor: "default" }}
          onClick={handleClose}
          data-testid="email-menu-item"
        >
          <ListItemIcon>
            <Email fontSize="small" />
          </ListItemIcon>
          {sessionIDInfo?.preferredMail || userInfo?.preferredMail}
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ cursor: "pointer" }}
          onClick={logout}
          data-testid="logout-menu-item"
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
